import gql from 'graphql-tag'

export const ORGANISATION_QUERY = gql`
query Organisation($id: ID!) {
  organisation(id: $id) {
    id
    idCode
    name
    logo
    logoHeight
    logoWidth
    phone
    publicName
    dvkgUrl
    city
    fax
    email
    street
    website
    instagram
    facebook
    linkedIn
    whatsapp
    youtube
    xing
    twitter
    zipCode
    countryIso2
    myjourneyWorthKnowing
    myjourneyCaseOfEmergency
    myjourneyOnSiteRepresentatives
    myjourneyTraffic
  }
}
`

export const ORGANISATION_UPDATE_MUTATION = gql`
mutation UpdateOrganisation($input: OrganisationUpdateGenericType!) {
  updateOrganisation(input: $input){
    organisation {
      id
    }
  }
}
`