import { DestinationRepository } from "@/repositories/destinationRepository"
import { MyJourneyRepository } from "@/repositories/myJourneyRepository"
import { MapRepository } from "@/repositories/mapRepository"

const RepositoriesPlugin = {
  install (Vue, store) {
    Vue.prototype.$destinationRepository = new DestinationRepository(store, Vue.prototype.$apollo)
    Vue.prototype.$myJourneyRepository = new MyJourneyRepository(store, Vue.prototype.$apollo)
    Vue.prototype.$mapRepository = new MapRepository(store, Vue.prototype.$apollo)
  }
}

export default RepositoriesPlugin