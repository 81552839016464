import gql from 'graphql-tag'

export const ORGANISATIONCOUNTRYSELECTIONS_QUERY = gql`
query OrganisationCountrySelections {
  organisationCountrySelections {
    results {
      id
      country {
        id
        nameShort
      }
    }
  }
}
`

export const ORGANISATIONCOUNTRYSELECTION_CREATE_MUTATION = gql`
mutation CreateOrganisationCountrySelection($input: OrganisationCountrySelectionCreateGenericType!) {
  createOrganisationCountrySelection(input: $input) {
    organisationcountryselection {
      id
      country {
        id
        nameShort
      }
    }
    ok
    errors {
      field
      messages
    }
  }
}
`

export const ORGANISATIONCOUNTRYSELECTION_DELETE_MUTATION = gql`
mutation DeleteOrganisationCountrySelection($id: ID!) {
  deleteOrganisationCountrySelection(id: $id) {
    organisationcountryselection {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`
