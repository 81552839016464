import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { ORGANISATIONCOUNTRYSELECTIONS_QUERY, ORGANISATIONCOUNTRYSELECTION_CREATE_MUTATION,
         ORGANISATIONCOUNTRYSELECTION_DELETE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: ORGANISATIONCOUNTRYSELECTIONS_QUERY,
    fetchAllFieldName: 'organisationCountrySelections',

    createMutation:  ORGANISATIONCOUNTRYSELECTION_CREATE_MUTATION,
    createMutationName: 'createOrganisationCountrySelection',
    createFieldName: 'organisationcountryselection',

    deleteMutation: ORGANISATIONCOUNTRYSELECTION_DELETE_MUTATION,
    deleteMutationName: 'deleteOrganisationCountrySelection',
    deleteFieldName: 'organisationcountryselection',
  },
  getters: {
    countryIds(state, getters, rootState) {
      return state.entries.map(item => item.country.id);
    }
  }
})
/* eslint-enable no-unused-vars */

export default store

