import gql from 'graphql-tag'

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++ FRAGMENTS+++++++++++++++++++++++++++++++++++++++ */
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


export const COUNTRY_FRAGMENT = `
fragment CountryFragment on CountryGenericType {
  id
  iso2
  iso3
  nameShort
  urlGmEvent
  idExternalGmEvent
}
`

export const REGION_FRAGMENT = `
fragment RegionFragment on RegionGenericType {
  id
  name
}
`

export const AIRPORT_FRAGMENT = `
fragment AirportFragment on AirportGenericType {
  id
  iata
  icao
  name
}
`

export const SOURCE_FRAGMENT = `
fragment EntryRestrictionFinalSourceFragment on EntryRestrictionFinalSource {
  authorityName
  authorityType
  modifiedAt
  significantUpdateAt
  noRestrictionsStart
  noRestrictionsEnd
  noAccessStart
  noAccessEnd
  someRestrictionsStart
  someRestrictionsEnd
  sourceUri
}
`

export const ENTRYRESTRICTIONFINALDETAILGROUP_FRAGMENT = `
fragment EntryRestrictionFinalDetailGroupFragment on EntryRestrictionFinalDetailGroup {
  key
  name
  details {
    name
    detailType
    status
    value
    note
  }
}
`

export const ENTRYRESTRICTIONFINALDEVIATION_FRAGMENT = `
fragment EntryRestrictionFinalDeviationFragment on EntryRestrictionFinalDeviation {
  region {
    id
    name
  }
  airport {
    id
    name
    iata
  }
  significantUpdateAt
  restrictionLevel
}
`


export const ENTRYRESTRICTIONDETAIL_FRAGMENT = `
fragment EntryRestrictionDetailFragment on EntryRestrictionDetailGenericType {
  id
  restrictionType
  noRestrictionsStart
  noRestrictionsStartSignificantUpdateAt
  noRestrictionsEnd
  noRestrictionsEndSignificantUpdateAt
  noAccessStart
  noAccessStartSignificantUpdateAt
  noAccessEnd
  noAccessEndSignificantUpdateAt
  someRestrictionsStart
  someRestrictionsStartSignificantUpdateAt
  someRestrictionsEnd
  someRestrictionsEndSignificantUpdateAt
  note
  noteSignificantUpdateAt
  noteDe
  noteEn
  noteRaw
  noteRawDe
  noteRawEn
  sourceUri
  modifiedAt
  significantUpdateAt
  restrictionDetailJson
  copyBase
  sourceUriCopyParent
  noRestrictionsStartCopyParent
  noRestrictionsEndCopyParent
  noAccessStartCopyParent
  noAccessEndCopyParent
  someRestrictionsStartCopyParent
  someRestrictionsEndCopyParent
  noteRawCopyParent
}
`

export const ENTRYRESTRICTION_FRAGMENT = `
fragment EntryRestrictionFragment on EntryRestrictionGenericType {
  id
  restrictionLevel
  isActive
  modifiedAt
  significantUpdateAt
  significantUpdateNote
}
`

export const ENTRYRESTRICTION_CATEGORY_SETTINGS = `

`

export const ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT = `
fragment EntryRestrictionWithRelationsFragment on EntryRestrictionGenericType {
  ...EntryRestrictionFragment
  country {
    ...CountryFragment
  }
  region {
    ...RegionFragment
  }
  airport {
    ...AirportFragment
  }
  entryrestrictiondetails{
    ...EntryRestrictionDetailFragment
    authority {
      id
      name
      authorityType
    }
    editCategories {
      category
      copyFromBusiness
    }
  }
}
${ENTRYRESTRICTION_FRAGMENT}
${COUNTRY_FRAGMENT}
${REGION_FRAGMENT}
${AIRPORT_FRAGMENT}
${ENTRYRESTRICTIONDETAIL_FRAGMENT}
`


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* ++++++++++++++++++++++++++++++++ MUTATIONS +++++++++++++++++++++++++++++++++++++++ */
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

export const TRANSLATE_TEXT_MUTATION = gql`
mutation TranslateText(
    $sourceLanguage: String!,
    $targetLanguage: String!,
    $text: String!
  ) {
  translateText(input: {
    sourceLanguage: $sourceLanguage,
    targetLanguage: $targetLanguage,
    text: $text,
  }) {
    text
    ok
  }
}
`





