import gql from 'graphql-tag'

export const ORGANISATIONUSER_QUERY = gql`
query OrganisationUser($id: ID!) {
  organisationUser(id: $id) {
    id
    firstName
    lastName
    email
  }
}
`

export const ORGANISATIONUSER_UPDATE_MUTATION = gql`
mutation UpdateOrganisationUser($input: OrganisationUserUpdateGenericType!) {
  updateOrganisationUser(input: $input){
    organisationuser {
      id
    }
  }
}
`