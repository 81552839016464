import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { ORGANISATIONUSER_QUERY, ORGANISATIONUSER_UPDATE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: ORGANISATIONUSER_QUERY,
    fetchFieldName: 'organisationUser',

    updateMutation:  ORGANISATIONUSER_UPDATE_MUTATION,
    updateMutationName: 'updateOrganisationUser',
    updateFieldName: 'organisationuser',
  },
  actions: {
    getIdFromUpdateVariables({ state, commit, rootState }, variables) {
      return variables.input.id
    },
  }
})
/* eslint-enable no-unused-vars */

export default store