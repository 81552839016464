<template>
  <div>
    {{ $t("main.LANGUAGE") }}:

    <span v-for="(languageDesc, language) in languages" :key="language">
      <v-btn
        v-if="language == currentLanguage"
        disabled
        class="ml-2"
        color="secondary">{{ languageDesc }}</v-btn>

      <v-btn
        v-if="language != currentLanguage"
        @click="select(language)"
        class="ml-2">{{ languageDesc }}</v-btn>
    </span>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'LanguageSelector',
  computed: {
    ...mapState({
      currentLanguage: state => state.uiStore.language,
      languages: state => state.uiStore.languages,
    })
  },
  methods: {
    ...mapMutations({
      SET_LANGUAGE: 'uiStore/SET_LANGUAGE'
    }),
    select(language) {
      this.SET_LANGUAGE(language)
      window.location.reload(true)
    }
  }
}

</script>
