import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { COUNTRY_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: COUNTRY_QUERY,
    fetchFieldName: 'country',
  },
})
/* eslint-enable no-unused-vars */

export default store

