import gql from 'graphql-tag'

export const ENTRYRESTRICTIONAUTHORITIES_QUERY = gql`
query EntryRestrictionAuthorities {
  entryRestrictionAuthorities {
    results(ordering: "id") {
      id
      authorityType
      name
    }
  }
}
`