import gql from 'graphql-tag'

import { ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT } from "../../graphql";


export const ENTRYRESTRICTIONS_QUERY = gql`
query EntryRestrictionsQuery($restrictionLevel: String!, $isActive: Boolean, $regionId: ID, $airportId: ID) {
  entryRestrictions(restrictionLevel: $restrictionLevel, isActive: $isActive, regionId: $regionId, airportId: $airportId) {
    results {
      ...EntryRestrictionWithRelationsFragment
    }
  }
}
${ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT}
`