import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { GMEVENTCOVIDUPDATE_CREATE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    createMutation: GMEVENTCOVIDUPDATE_CREATE_MUTATION,
    createMutationName: 'createGmEventCovidUpdate',
    createFieldName: 'gmEventCovidUpdate',
  },
})
/* eslint-enable no-unused-vars */

export default store

