import Vue from 'vue'
import apolloClient from '@/apolloClient'
import { apolloVuexBaseFactory } from './ApolloVuexBaseStore'

/* eslint-disable no-unused-vars */
let defaultStore = {
  mutations: {
    setIsLoading(state, isLoading) { state.isLoading = isLoading },

    /**
     * Creates the basic structure of an entry if needed, and adds missing fields if needed
     */
    prepareEntry(state, id) {
      let lookupId = id.toString()
      if (state.entries[lookupId] === undefined) { Vue.set(state.entries, lookupId, {}) }

      if (state.entries[lookupId]['id'] === undefined) { Vue.set(state.entries[lookupId], 'id', id) }
      if (state.entries[lookupId]['data'] === undefined) { Vue.set(state.entries[lookupId], 'data', {}) }
      if (state.entries[lookupId]['isLoading'] === undefined) { Vue.set(state.entries[lookupId], 'isLoading', false) }
      if (state.entries[lookupId]['hasErrors'] === undefined) { Vue.set(state.entries[lookupId], 'hasErrors', false) }
      if (state.entries[lookupId]['errors'] === undefined) { Vue.set(state.entries[lookupId], 'errors', []) }
      if (state.entries[lookupId]['isDone'] === undefined) { Vue.set(state.entries[lookupId], 'isDone', false) }
      if (state.entries[lookupId]['isDirty'] === undefined) { Vue.set(state.entries[lookupId], 'isDirty', false) }
    },

    setEntryData(state, content) { Vue.set(state.entries[content.id.toString()], 'data', content.data) },
    setEntryErrors(state, content) { Vue.set(state.entries[content.id.toString()], 'errors', content.errors) },
    setEntryHasErrors(state, content) { Vue.set(state.entries[content.id.toString()], 'hasErrors', content.hasErrors) },
    setEntryIsLoading(state, content) { Vue.set(state.entries[content.id.toString()], 'isLoading', content.isLoading) },
    setEntryIsDirty(state, content) { Vue.set(state.entries[content.id.toString()], 'isDirty', content.isDirty) },
    setEntryIsDone(state, content) { Vue.set(state.entries[content.id.toString()], 'isDone', content.isDone) },
    clear(state, content) { state.entrie = [] },

    removeEntryById(state, id) {
      let lookupId = id.toString()
      Vue.delete(state.entries, lookupId)
    }
  },

  getters: {
    getById: (state, getters, rootState) => id => {
      let lookupId = id.toString()
      return state.entries[lookupId]
    },
  },

  actions: {

    /**
     * params: {
     *   variables:    object,  default={},    Optional
     *   force:        boolean, default=False, Optional
     *   emptyOnError: boolean, default=False, Optional. If True, the entries will be = [] on error. Without, we keep old entries
     * }
     */
    async fetch({ state, commit, rootState, dispatch }, params) {
      if (params === undefined) {
        params = {}
      }

      let force = Object.prototype.hasOwnProperty.call(params, "force") ? params['force'] : false
      let emptyOnError = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : false
      let variables = Object.prototype.hasOwnProperty.call(params, "variables") ? params['variables'] : {}

      let hasError = false
      let errors = []
      let entryData = []

      variables = await dispatch('prepareFetchVariables', variables)
      let id = await dispatch('getIdFromFetchVariables', variables)

      // we must ensure that the id is always of the same type. So we use strings for the entries keys
      id = id.toString()

      // checks if the entry already exists. If not, we create one and fill it with the required fields
      commit('prepareEntry', id)

      let queryServer = true
      /* check if we really need to query the server. If our local state is fine, then we
          skip that */
      if (!force && !state.entries[id].isDirty && state.entries[id].isDone) {
        queryServer = false
        entryData = state.entries[id]['data']
      }

      if (queryServer) {
        commit('setIsLoading', true)
        commit('setEntryIsLoading', {id: id, isLoading: true})
        try {
          /* the GraphQL query execution. Returns the dict
            from the parsed json result */
          let result = await dispatch('query', {
              query: state.settings.fetchQuery,
              variables: variables
            }, {
              root: true
            }
          )

          entryData = await dispatch('extractFetchResultEntryData', result)

          let successParam = {params: params, id: id, variables: variables, result: result, entryData: entryData}
          await dispatch('onSuccess', successParam)
          await dispatch('onFetchSuccess', successParam)

          // reset values fixed by the result
          commit('setEntryIsDirty', {id: id, isDirty: false})
          commit('setEntryHasErrors', {id: id, hasErrors: false})
          commit('setEntryErrors', {id: id, errors: []})

          // set states relevant to the fetch
          commit('setIsLoading', false)
          commit('setEntryIsLoading', {id: id, isLoading: false})
          commit('setEntryIsDone', {id: id, isDone: true})

          commit('setEntryData', {id: id, data: entryData})
        } catch(error) {
          await dispatch('onError', error)
          await dispatch('onFetchError', error)
          hasError = true
          errors.push(error)

          // reset loading state. Important: isDirty is untouched. And we are not "done"
          commit('setIsLoading', false)

          // set error status
          commit('setEntryHasErrors', {id: id, hasErrors: true})
          commit('setEntryErrors', {id: id, errors: errors})

          if (emptyOnError) {
            // when we reset Entries, we also must mark it as "not done", to trigger a reload
            commit('setEntryData', {id: id, data: {}})
            commit('setEntryIsDone', {id: id, isDone: false})
          }
        }
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entryData)
        }
      })
    },

    /**
     * params {
     *   variables:     Object, required
     * }
     *
     * Important: Create will NOT call any of the setEntry* mutations on its own, plus it will NOT
     * create the entry. BUT it will return the created entryData, so that the caller can do that on his own.
     * Second BUT: updateEntryDataOnCreate will be called, it can (if needed) create the entry
     */
    async create({ state, commit, rootState, dispatch }, params) {
      let variables = params['variables']

      let hasError = false
      let errors = []

      let entryData = {}

      variables = await dispatch('prepareCreateVariables', variables)

      commit('setIsLoading', true)
      try {
        let result = await dispatch('query', {
            query: state.settings.createMutation,
            variables: variables
          }, {
            root: true
          }
        )


        entryData = await dispatch('extractCreateResultEntryData', result)

        let successParam = {params: params, variables: variables, result: result, entryData: entryData}
        await dispatch('onSuccess', successParam)
        await dispatch('onCreateSuccess', successParam)

        await dispatch('updateEntryDataOnCreate', successParam)

        // set states relevant to the create
        // isDone is not touched, as this marks if the fetch call was finished already. BUT it can be set
        // inside of updateEntryDataOnUpdate if the data is updated
        commit('setIsLoading', false)
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onUpdateError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entryData)
        }
      })
    },

    /**
     * params {
     *   variables: Object, required
     *   dirtyOnSuccess: Boolean, default=True, Optional. Makes isDirty=True on success
     * }
     */
    async update({ state, commit, rootState, dispatch }, params) {
      let variables = params['variables']
      let dirtyOnSuccess = Object.prototype.hasOwnProperty.call(params, "emptyOnError") ? params['emptyOnError'] : true

      let hasError = false
      let errors = []

      let entryData = {}

      variables = await dispatch('prepareUpdateVariables', variables)
      let id = await dispatch('getIdFromUpdateVariables', variables)

      // we must ensure that the id is always of the same type. So we use strings for the entries keys
      id = id.toString()

      // checks if the entry already exists. If not, we create one and fill it with the required fields
      commit('prepareEntry', id)

      commit('setIsLoading', true)
      commit('setEntryIsLoading', {id: id, isLoading: true})
      try {
        let result = await dispatch('query', {
            query: state.settings.updateMutation,
            variables: variables
          }, {
            root: true
          }
        )

        entryData = await dispatch('extractUpdateResultEntryData', result)

        const isOk = result.data[state.settings.updateMutationName]['ok']
        const apiError = result.data[state.settings.updateMutationName]['error']
        if (isOk === false) {
          throw apiError
        }

        let successParam = {params: params, id: id, variables: variables, result: result, entryData: entryData}
        await dispatch('onSuccess', successParam)
        await dispatch('onUpdateSuccess', successParam)

        // reset values fixed by the result
        commit('setEntryHasErrors', {id: id, hasErrors: false})
        commit('setEntryErrors', {id: id, errors: []})

        /* We updated the data on the server side, but if we don't have a working updateEntryDataOnUpdate
           function, our local cache would be outdated. So per default we mark it as dirty.
           We can prevent that with the dirtyOnSuccess option, or reset the dirty state in the
           updateEntryDataOnUpdate call! The last one is the better approach on a global scale */
        if (dirtyOnSuccess) {
          commit('setEntryIsDirty',  {id: id, isDirty: true})
        }

        await dispatch('updateEntryDataOnUpdate', successParam)

        // set states relevant to the update
        // isDone is not touched, as this marks if the fetch call was finished already. BUT it can be set
        // inside of updateEntryDataOnUpdate if the data is updated
        commit('setIsLoading', false)
        commit('setEntryIsLoading', {id: id, isLoading: false})
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onUpdateError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)
        commit('setEntryIsLoading', {id: id, isLoading: false})

        // set error status
        commit('setEntryHasErrors', {id: id, hasErrors: true})
        commit('setEntryErrors', {id: id, errors: errors})
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entryData)
        }
      })
    },

    /**
     * params {
     *   id: String/Int/ID, required.
     * }
     */
    async delete({ state, commit, rootState, dispatch }, params) {
      let id = params['id']

      let hasError = false
      let errors = []

      let entryData = {}

      commit('setIsLoading', true)
      try {
        let result = await dispatch('query', {
            query: state.settings.deleteMutation,
            variables: {
              id: id
            }
          }, {
            root: true
          }
        )

        entryData = await dispatch('extractDeleteResultEntryData', result)

        // Remove the entry from the state completely. Because of this, we do not need to fix any of the
        // status of the entry after that
        commit('removeEntryById', id)

        let successParam = {params: params, id: id, result: result, entryData: entryData}
        await dispatch('onSuccess', successParam)
        await dispatch('onDeleteSuccess', successParam)

        // set states relevant to the delete
        commit('setIsLoading', false)
      } catch(error) {
        await dispatch('onError', error)
        await dispatch('onDeleteError', error)
        hasError = true
        errors.push(error)

        // reset loading state. Important: isDirty is untouched. And we are not "done"
        commit('setIsLoading', false)
      }

      return new Promise((resolve, reject) => {
        if (hasError) {
          reject(errors)
        } else {
          resolve(entryData)
        }
      })
    },

    /* These actions can be overwritten and are used to do work in the variables before the
        query or mutation is called */
    async prepareFetchVariables({ state, commit, rootState }, variables) {
      return variables
    },
    async prepareCreateVariables({ state, commit, rootState }, variables) {
      return variables
    },
    async prepareUpdateVariables({ state, commit, rootState }, variables) {
      return variables
    },

    /* We need a unique id per entry, but sometimes we might need more or complex queries, that does not have
       a unique id at query time. We we need to define one. This function can be overwritten to create
       a unique id out of the variables.
       Per default, we expect an "id" in the variables */
    getIdFromFetchVariables({ state, commit, rootState }, variables) {
      return variables.id
    },
    getIdFromUpdateVariables({ state, commit, rootState }, variables) {
      return variables.id
    },

    /* Thee actions can be overwritten if we need to extract the content in a different way,
        of if we need to process them after the fetch */
    extractFetchResultEntryData({ state, commit, rootState }, result) {
      return result.data[state.settings.fetchFieldName]
    },
    extractCreateResultEntryData({ state, commit, rootState }, result) {
      return result.data[state.settings.createMutationName][state.settings.createFieldName]
    },
    extractUpdateResultEntryData({ state, commit, rootState }, result) {
      return result.data[state.settings.updateMutationName][state.settings.updateFieldName]
    },
    extractDeleteResultEntryData({ state, commit, rootState }, result) {
      return result.data[state.settings.deleteMutationName][state.settings.deleteFieldName]
    },

    /* On Mutations, the cache can be updated for an optimistic approach, but this must be done manually */
    updateEntryDataOnCreate({ state, commit, rootState }, content) {},
    updateEntryDataOnUpdate({ state, commit, rootState }, content) {},

    /* These actions can be overwritten to react to events.
        Typical usages would be the manipulation of the returned data before it is
        put in the store */
    onSuccess({ state, commit, rootState }, content) {},
    onError({ state, commit, rootState }, params) {},

    onFetchSuccess({ state, commit, rootState }, content) {},
    onFetchError({ state, commit, rootState }, errors) {},

    onCreateSuccess({ state, commit, rootState }, content) {},
    onCreateError({ state, commit, rootState }, errors) {},

    onUpdateSuccess({ state, commit, rootState }, content) {},
    onUpdateError({ state, commit, rootState }, errors) {},

    onDeleteSuccess({ state, commit, rootState }, content) {},
    onDeleteError({ state, commit, rootState }, errors) {},
  }
}

function apolloVuexSingleStoreFactory(params) {
  let store = {
    namespaced: true,

    state: {
      settings: {
        fetchQuery: null,
        fetchFieldName: null,

        createMutation: null,
        createMutationName: null,
        createFieldName: null,

        updateMutation: null,
        updateMutationName: null,
        updateFieldName: null,

        deleteMutation: null,
        deleteMutationName: null,
        deleteFieldName: null,
      },

      entries: {},
      isLoading: false,
    },
    actions: {},
    getters: {},
    mutations: {}
  }

  return apolloVuexBaseFactory(store, defaultStore, params)
}
/* eslint-enable no-unused-vars */

export { apolloVuexSingleStoreFactory }