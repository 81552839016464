<template>
  <v-menu
    v-model="menu"
    :offsetY="true"
    z-index="1010"
  >
    <template v-slot:activator="{ on }">
      <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
    </template>
    <v-list>
      <v-list-item
        v-if="$hasPermission('a3m_core.ContentEditor')"
        :to="{name: 'entryrestrictions'}">
        <v-list-item-title>{{ $t("main.ENTRY_RESTRICTIONS") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$hasPermission('a3m_core.ContentEditor')"
        :to="{name: 'textsnippet-list'}">
        <v-list-item-title>{{ $t("main.TEXTSNIPPET") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$hasPermission('a3m_core.NewsEditor')"
        :to="{name: 'news-admin-list'}">
        <v-list-item-title>{{ $t("main.NEWS") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$hasPermission('a3m_core.ContentEditor')"
        :to="{name: 'internal-entryrestriction-overview'}">
        <v-list-item-title>Ampel-Felder</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="user.isStaff"
        href="/admin" target="_blank">
        <v-list-item-title>{{ $t("main.ADMIN") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :href="$t('main.IMPRINT_URL')" target="_blank">
        <v-list-item-title>{{ $t("main.IMPRINT") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ToolbarBurgerMenu',
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
    }),
  }
};
</script>