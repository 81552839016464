export class BaseRepository {
  constructor(store, apollo) {
    this.store = store
    this.apollo = apollo
  }

  async query(query, variables, fieldName) {
    return new Promise((resolve, reject) => {
      this.store.dispatch('query', {
        query: query,
        variables: variables
      }, {
        root: true
      })
      .then((result) => {
        resolve(result.data[fieldName])
      })
      .catch((error) => {
        reject([error])
      })
    })
  }
}