import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { ENTRYRESTRICTIONAUTHORITIES_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: ENTRYRESTRICTIONAUTHORITIES_QUERY,
    fetchAllFieldName: 'entryRestrictionAuthorities',
  },
})
/* eslint-enable no-unused-vars */

export default store

