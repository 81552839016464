import gql from 'graphql-tag'

export const TICKER_QUERY = gql`
query Ticker {
  ticker(limit: 100) {
    results {
      id
      text
      textDe
      textEn
      title
      titleDe
      titleEn
      importantUntil
      newsType
      country {
        id
        nameShort
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      modifiedAt
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateAt
    }
  }
}
`
