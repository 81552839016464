import router from '../../../router'
import apolloClient from '../../../apolloClient'
import moment from "moment"

/**
 * The uiStore holds states of the user interface, like selections, that must be remembers as long
 * as the application is running.
 */
const store = {
  namespaced: true,

  state: {
    // german is our default language, but can be overwritten by the local storage
    // and/or change in the app at runtime
    language: "de",

    languages: {
      'de': 'Deutsch',
      'en': 'English',
    },

    languagesMyTrip: {
      'de': 'Deutsch',
      'en': 'English',
      'fr': 'Français',
      'it': 'Italiano',
      'nl': 'Nederlands',
      'pl': 'Polski',
      'pt': 'Português',
      'es': 'Español',   
      'cs': 'Česky',
      'tr': 'Türkçe', 
      'hu': 'Magyar',                     
    },

    // Holds the currently selected touroperators from the assortments.
    // Used in Destination (Detailansicht) view
    assortmentTouroperators: [],

    // the restriction type (BUSINESS, TOURISM) is stored globally for the app, so all
    // fields should show the same value
    restrictionType: "BUSINESS",

    // when the user viewed the news (in the newsboard/index) last time.
    // All news newer than that will be marked as "new"
    lastNewsVisitDate: null,

    // open the pinned event pane when index page is shown. State saved in the local storage
    openPinnedEvents: false,

    destinationLookupHistory: [],
    countryLookupHistory: []
  },

  getters: {
    unreadNewsCount(state, getters, rootState, rootGetters) {
      let count = 0
      count = count + rootGetters['newsStore/breakingNews'].filter(entry => entry.isUnread).length
      count = count + rootGetters['newsStore/destinationManagerNews'].filter(entry => entry.isUnread).length
      count = count + rootGetters['tickerStore/withIsUnread'].filter(entry => entry.isUnread).length
      //count = count + rootGetters['entryRestrictionLatestSignificantUpdatesStore/withIsUnread'].filter(entry => entry.isUnread).length
      count = count + rootGetters['eventsLatestSignificantUpdatesStore/withIsUnread'].filter(entry => entry.isUnread).length
      return count
    },
  },

  mutations: {
    SET_LANGUAGE(state, language)  {
      state.language = language

      localStorage.setItem("language", language)
    },

    SET_ASSORTMENTTOUROPERATORS(state, assortmentTouroperators)  {
      state.assortmentTouroperators = assortmentTouroperators
    },

    SET_RESTRICTIONTTYPE(state, restrictionType) {
      state.restrictionType = restrictionType

      localStorage.setItem("restrictionType", restrictionType)
    },

    /**
     * lastNewsVisitDate must be a moment (!) object!
     */
    SET_LASTNEWSVISITDATE(state, lastNewsVisitDate) {
      state.lastNewsVisitDate = lastNewsVisitDate

      localStorage.setItem("lastNewsVisitDate", lastNewsVisitDate.format())
    },

    SET_OPEN_PINNED_EVENTS(state, openPinnedEvents) {
      state.openPinnedEvents = openPinnedEvents

      if (openPinnedEvents) {
        localStorage.setItem("openPinnedEvents", "1")
      } else {
        localStorage.setItem("openPinnedEvents", "0")
      }
    },

    SET_DESTINATION_LOOKUP_HISTORY(state, items) {
      state.destinationLookupHistory = items
      localStorage.setItem("destinationLookupHistory", JSON.stringify(state.destinationLookupHistory))
    },

    ADD_DESTINATION_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.destinationLookupHistory.unshift(lookupItem)

        if (state.destinationLookupHistory.length > 50) {
          state.destinationLookupHistory = state.destinationLookupHistory.slice(0, 50)
        }

        localStorage.setItem("destinationLookupHistory", JSON.stringify(state.destinationLookupHistory))
      }
    },

    SET_COUNTRY_LOOKUP_HISTORY(state, items) {
      state.countryLookupHistory = items
      localStorage.setItem("countryLookupHistory", JSON.stringify(state.countryLookupHistory))
    },

    ADD_COUNTRY_LOOKUP_HISTORY(state, lookupItem) {
      if (lookupItem !== null){
        lookupItem.lookupDate = moment(new Date(), moment.ISO_8601).format()
        state.countryLookupHistory.unshift(lookupItem)

        if (state.countryLookupHistory.length > 50) {
          state.countryLookupHistory = state.countryLookupHistory.slice(0, 50)
        }

        localStorage.setItem("countryLookupHistory", JSON.stringify(state.countryLookupHistory))
      }
    }

  },

  actions: {
    initStore({ commit, dispatch }) {
      // set language if defined in the local storage
      // get default restrictionType
      if (localStorage.getItem("language")) {
        commit("SET_LANGUAGE", localStorage.getItem("language"))
      }

      // get default restrictionType
      if (localStorage.getItem("restrictionType")) {
        commit("SET_RESTRICTIONTTYPE", localStorage.getItem("restrictionType"))
      }

      // Get destinationLookupHistory as json
      if (localStorage.getItem("destinationLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("destinationLookupHistory"))
        commit("SET_DESTINATION_LOOKUP_HISTORY", items)
      }

      // Get countryLookupHistory as json
      if (localStorage.getItem("countryLookupHistory")) {
        let items = JSON.parse(localStorage.getItem("countryLookupHistory"))
        commit("SET_COUNTRY_LOOKUP_HISTORY", items)
      }

      // localStorage only stores strings, so we must handle bools on our own
      if (localStorage.getItem("openPinnedEvents")) {
        if (localStorage.getItem("openPinnedEvents") == "1") {
          commit("SET_OPEN_PINNED_EVENTS", true)
        } else {
          commit("SET_OPEN_PINNED_EVENTS", false)
        }
      }

      // init lastNewsVisitDate fromt the localStore. We will update it alongside
      // the store from here on
      let lastIndexVisited = localStorage.getItem("lastNewsVisitDate")
      if (lastIndexVisited != null) {
        commit("SET_LASTNEWSVISITDATE", moment(lastIndexVisited))
      } else {
        // default: we set a very old default date for the components
        commit("SET_LASTNEWSVISITDATE", moment().subtract({years: 20}))
      }

      // to get the most up-to-date newscount, we pull in new news and last changed every 5 minutes
      setInterval(() => {
        // auto reload the news only when the user is logged in
        if (localStorage.getItem("authToken")) {
          dispatch("fetchNewsAndLatestUpdates")
        }
      }, 300000);
    },

    /**
     * Required to update the newsboard new-news counter
     * Placed here, as it includes calls for multiple other stores
     */
    fetchNewsAndLatestUpdates({ commit, dispatch }) {
      // will return the promise when all requests finished successful, or the first failure
      return Promise.all([
        dispatch("newsStore/fetchAll", {force: true}, {root: true}),
        dispatch("tickerStore/fetchAll", {force: true}, {root: true}),
        //dispatch("entryRestrictionLatestSignificantUpdatesStore/fetchAll", {force: true}, {root: true}),
        dispatch("eventsLatestSignificantUpdatesStore/fetchAll", {force: true}, {root: true}),
        dispatch("eventsLatestSignificantUpdatesPinnedStore/fetchAll", {force: true}, {root: true}),
        dispatch("organisationCountrySelectionsStore/fetchAll", {force: true}, {root: true})
      ])
    },

    setLastNewsVisitDateToNow({ commit, dispatch }) {
      commit("SET_LASTNEWSVISITDATE", moment())
    }
  }
}

export default store