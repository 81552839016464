import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { COUNTRIES_ENTRYRESTRICTIONS_QUERY } from "./graphql";

function getCountry(countries, id) {
  return countries.find(country => country.id == id)
}

function getRegion(regions, id, defaultRegion) {
  let region = regions.find(region => region.id == id)
  if (!region) {
    // copy, to prevent cycle references, as we will attach the parent object of the region,
    // the EntryRestrictions, to the region later on
    region = {...defaultRegion}
    region['airports'] = []
  }
  return region
}

function getAirport(airports, id, defaultAirport) {
  let airport = airports.find(airport => airport.id == id)
  if (!airport) {
    // copy, to prevent cycle references, as we will attach the parent object of the airport,
    // the EntryRestrictions, to the airport later on
    airport = {...defaultAirport}
  }
  return airport
}


/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: COUNTRIES_ENTRYRESTRICTIONS_QUERY,
    fetchAllFieldName: 'countries',
  },

  actions: {
    extractFetchAllResultEntries({ state, commit, rootState }, result) {
      let countries = result.data['countries'].results
      let entryRestrictions = result.data['entryRestrictions'].results

      /* set some defaults */
      for (let country of countries) {
        if (country['entryRestriction'] === undefined) {
          country['entryRestriction'] = null
        }
        if (country['regions'] === undefined) {
          country['regions'] = []
        }
        if (country['airports'] === undefined) {
          country['airports'] = []
        }
      }

      // We might find the geo entries in the wrong order, so we loop the entryRestrictions per geo level, from top to bottom
      for (let entryRestriction of entryRestrictions) {
        let country = getCountry(countries, entryRestriction.country.id)

        if (entryRestriction.restrictionLevel == 'COUNTRY') {
          country['entryRestriction'] = entryRestriction
        }
      }

      for (let entryRestriction of entryRestrictions) {
        let country = getCountry(countries, entryRestriction.country.id)

        if (entryRestriction.restrictionLevel == 'REGION') {
          let foundRegion = country.regions.find(region => region.id == entryRestriction.region.id)
          if (!foundRegion) {
            let region = getRegion(country.regions, entryRestriction.region.id, entryRestriction.region)
            region['entryRestriction'] = entryRestriction
            country.regions.push(region)
          }
        }
      }

      for (let entryRestriction of entryRestrictions) {
        let country = getCountry(countries, entryRestriction.country.id)

        // regional airport
        if (entryRestriction.restrictionLevel == 'AIRPORT' && entryRestriction.region) {
          let region = country.regions.find(region => region.id == entryRestriction.region.id)
          if (!region) {
            region = getRegion(country.regions, entryRestriction.region.id, entryRestriction.region)
            region['entryRestriction'] = null
            country.regions.push(region)
          }

          let airport = getAirport(region.airports, entryRestriction.airport.id, entryRestriction.airport)
          airport['entryRestriction'] = entryRestriction

          region.airports.push(airport)
        }
      }

      for (let entryRestriction of entryRestrictions) {
        let country = getCountry(countries, entryRestriction.country.id)

        // country airport
        if (entryRestriction.restrictionLevel == 'AIRPORT' && !entryRestriction.region) {
          let airport = getAirport(country.airports, entryRestriction.airport.id, entryRestriction.airport)
          airport['entryRestriction'] = entryRestriction
          country.airports.push(airport)
        }
      }

      return countries
    },
  }
})
/* eslint-enable no-unused-vars */

export default store

