import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { EVENTS_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: EVENTS_QUERY,
    fetchAllFieldName: 'eventsLatestSignificantUpdates',
  },
  getters: {
    markUnreadEntries: (state, getters, rootState) => (entries) => {
        if (!Array.isArray(entries)) {
            return [];
        }
        return entries.map(entry => {
            entry.isUnread = moment(entry.significantUpdateAt) > rootState.uiStore.lastNewsVisitDate;
            return entry;
        });
    },

    withIsUnread(state, getters, rootState) {
        let events = state.entries.events || [];
        return getters.markUnreadEntries(events);
    },

    withIsUnreadLive(state, getters, rootState) {
        let liveEvents = state.entries.liveEvents || [];
        return getters.markUnreadEntries(liveEvents);
    }
  }
})
/* eslint-enable no-unused-vars */

export default store

