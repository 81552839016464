<template>

  <div class="network-bg">
    <div class="network-bg-art">

      <div class="login-box">
        <img src="@/assets/logo_dm.png" class="logo"/>

        <p>{{ $t('main.PLEASE_LOGIN') }}</p>

        <p v-if="showLoginError">{{ $t('main.LOGIN_FAILED_MESSAGE') }}</p>

        <v-form @submit.prevent="submitLogin">
          <v-text-field
            :label="$t('main.USERNAME')"
            :autofocus=true
            v-model="username"
            :rules="[rules.required]" />
          <v-text-field
            :label="$t('main.PASSWORD')"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :rules="[rules.required]"
            @click:append="showPassword = !showPassword" />

          <v-btn type="submit" color="primary">{{ $t('main.DO_LOGIN') }}</v-btn>
        </v-form>

        <div class="language-selector">
          <LanguageSelector />
        </div>

        <p class="cookie-notice first" v-html="$t('main.COOKIE_NOTICE')" />
      </div>

      <a href="https://www.global-monitoring.com/" target="_blank" class="imprint">A3M Global Monitoring GmbH, Alter Fischmarkt 5, DE-20457 Hamburg</a>
    </div>
  </div>

</template>

<script>
import { mapActions } from "vuex";
import LanguageSelector from '@/components/ui/LanguageSelector'

export default {
  name: "AppLogin",
  components: {
    LanguageSelector
  },
  data() {
    return {
      username: "",
      password: "",
      showLoginError: false,
      showPassword: false,
      rules: {
        required: value => !!value || this.$t('main.REQUIRED_FIELD')
      },
    }
  },
  methods: {
    ...mapActions({
      login: 'authStore/login'
    }),

    submitLogin: function() {
      this.login({
        username: this.username,
        password: this.password
      }).then(() => {
        this.showLoginError = false
        this.$router.push({name: "index"});
      }).catch(() => {
        this.showLoginError = true
      })
    }
  }
};
</script>

<style scoped>


  .login-box {
    background-color: white;
    padding: 3rem;
    max-width: 500px;
    border: 1px solid #af9393;
    margin-top: 200px;
    margin: 200px auto;
    opacity: 1;
    box-shadow: 0 25px 60px rgba(0,0,0,0.25);
    border-radius: 6px;
  }

  .logo {
    width: 100%;
    margin-bottom: 1rem;
  }

  h2 {
    font-weight: normal;
    color: gray;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .login-button {
    margin-top: 2rem;
  }

  .imprint {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    color: #d9d9d9;
    text-decoration: none;
  }

  .cookie-notice {
    color: gray;
    font-size: 90%;
    margin-top: 2rem;
  }

  .cookie-notice.second {
    margin-top: 1rem;
  }

  .cookie-notice-sep {
    margin: 0 8rem;
  }


  .language-selector {
    margin: 2rem 2rem;
    text-align: center;
  }

</style>