import i18n from '../../i18n.js'

// DO NOT CHANGE
// Created by "DetailGenerator"!
// Src: generate/generators/details/details.py
// Content: generate/content/details.py

export const generatedEntryRestrictionDetailTypesCategories = {
    
    "EntryRequirements": {
        i18n: 'detailcategory.EntryRequirements',
        title: i18n.t('detailcategory.EntryRequirements'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsPcrTest": {
        i18n: 'detailcategory.EntryRequirementsPcrTest',
        title: i18n.t('detailcategory.EntryRequirementsPcrTest'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsQuickTest": {
        i18n: 'detailcategory.EntryRequirementsQuickTest',
        title: i18n.t('detailcategory.EntryRequirementsQuickTest'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsTmaTest": {
        i18n: 'detailcategory.EntryRequirementsTmaTest',
        title: i18n.t('detailcategory.EntryRequirementsTmaTest'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsLampTest": {
        i18n: 'detailcategory.EntryRequirementsLampTest',
        title: i18n.t('detailcategory.EntryRequirementsLampTest'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsOthers": {
        i18n: 'detailcategory.EntryRequirementsOthers',
        title: i18n.t('detailcategory.EntryRequirementsOthers'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsVaccinations": {
        i18n: 'detailcategory.EntryRequirementsVaccinations',
        title: i18n.t('detailcategory.EntryRequirementsVaccinations'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsPartialVaccinations": {
        i18n: 'detailcategory.EntryRequirementsPartialVaccinations',
        title: i18n.t('detailcategory.EntryRequirementsPartialVaccinations'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "EntryRequirementsRecovered": {
        i18n: 'detailcategory.EntryRequirementsRecovered',
        title: i18n.t('detailcategory.EntryRequirementsRecovered'),
        backgroundColor: "lightblue",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ConditionsOnSite": {
        i18n: 'detailcategory.ConditionsOnSite',
        title: i18n.t('detailcategory.ConditionsOnSite'),
        backgroundColor: "lightgreen",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ConditionsOnSiteEasedRestrictions": {
        i18n: 'detailcategory.ConditionsOnSiteEasedRestrictions',
        title: i18n.t('detailcategory.ConditionsOnSiteEasedRestrictions'),
        backgroundColor: "lightgreen",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "DepartureRegulations": {
        i18n: 'detailcategory.DepartureRegulations',
        title: i18n.t('detailcategory.DepartureRegulations'),
        backgroundColor: "gray",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulations": {
        i18n: 'detailcategory.ReentryRegulations',
        title: i18n.t('detailcategory.ReentryRegulations'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsRKI": {
        i18n: 'detailcategory.ReentryRegulationsRKI',
        title: i18n.t('detailcategory.ReentryRegulationsRKI'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsTestsBefore": {
        i18n: 'detailcategory.ReentryRegulationsTestsBefore',
        title: i18n.t('detailcategory.ReentryRegulationsTestsBefore'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsTestsAfter": {
        i18n: 'detailcategory.ReentryRegulationsTestsAfter',
        title: i18n.t('detailcategory.ReentryRegulationsTestsAfter'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsQuarantine": {
        i18n: 'detailcategory.ReentryRegulationsQuarantine',
        title: i18n.t('detailcategory.ReentryRegulationsQuarantine'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsVaccinations": {
        i18n: 'detailcategory.ReentryRegulationsVaccinations',
        title: i18n.t('detailcategory.ReentryRegulationsVaccinations'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsPartialVaccinations": {
        i18n: 'detailcategory.ReentryRegulationsPartialVaccinations',
        title: i18n.t('detailcategory.ReentryRegulationsPartialVaccinations'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "ReentryRegulationsRecovered": {
        i18n: 'detailcategory.ReentryRegulationsRecovered',
        title: i18n.t('detailcategory.ReentryRegulationsRecovered'),
        backgroundColor: "orange",
        color: "black",
        allowCopyFromBusiness: true,
    },
    
    "IncidenceStatistic": {
        i18n: 'detailcategory.IncidenceStatistic',
        title: i18n.t('detailcategory.IncidenceStatistic'),
        backgroundColor: "black",
        color: "white",
        allowCopyFromBusiness: false,
    },
    
    "HealthInformation": {
        i18n: 'detailcategory.HealthInformation',
        title: i18n.t('detailcategory.HealthInformation'),
        backgroundColor: "blue",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErTravelDocumentsPassport": {
        i18n: 'detailcategory.ErTravelDocumentsPassport',
        title: i18n.t('detailcategory.ErTravelDocumentsPassport'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErTravelDocumentsTemporaryPassport": {
        i18n: 'detailcategory.ErTravelDocumentsTemporaryPassport',
        title: i18n.t('detailcategory.ErTravelDocumentsTemporaryPassport'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErTravelDocumentsID": {
        i18n: 'detailcategory.ErTravelDocumentsID',
        title: i18n.t('detailcategory.ErTravelDocumentsID'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErTravelDocumentsTemporaryID": {
        i18n: 'detailcategory.ErTravelDocumentsTemporaryID',
        title: i18n.t('detailcategory.ErTravelDocumentsTemporaryID'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErTravelDocumentsChildrenPassport": {
        i18n: 'detailcategory.ErTravelDocumentsChildrenPassport',
        title: i18n.t('detailcategory.ErTravelDocumentsChildrenPassport'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: false,
    },
    
    "ErTravelDocumentsGeneral": {
        i18n: 'detailcategory.ErTravelDocumentsGeneral',
        title: i18n.t('detailcategory.ErTravelDocumentsGeneral'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErVisaRequirement": {
        i18n: 'detailcategory.ErVisaRequirement',
        title: i18n.t('detailcategory.ErVisaRequirement'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErCompulsoryHealthInsurance": {
        i18n: 'detailcategory.ErCompulsoryHealthInsurance',
        title: i18n.t('detailcategory.ErCompulsoryHealthInsurance'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErEntry": {
        i18n: 'detailcategory.ErEntry',
        title: i18n.t('detailcategory.ErEntry'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErExit": {
        i18n: 'detailcategory.ErExit',
        title: i18n.t('detailcategory.ErExit'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErDualNationals": {
        i18n: 'detailcategory.ErDualNationals',
        title: i18n.t('detailcategory.ErDualNationals'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "ErRegulationsForMinors": {
        i18n: 'detailcategory.ErRegulationsForMinors',
        title: i18n.t('detailcategory.ErRegulationsForMinors'),
        backgroundColor: "darkgreen",
        color: "white",
        allowCopyFromBusiness: false,
    },
    
    "Monkeypox": {
        i18n: 'detailcategory.Monkeypox',
        title: i18n.t('detailcategory.Monkeypox'),
        backgroundColor: "brown",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
    "MoreAboutDestination": {
        i18n: 'detailcategory.MoreAboutDestination',
        title: i18n.t('detailcategory.MoreAboutDestination'),
        backgroundColor: "blue",
        color: "white",
        allowCopyFromBusiness: true,
    },
    
}

export const generatedEntryRestrictionDetailTypes = {
    
    "EntryRequirements": {
        type: "FREETEXT",
        i18n: "detailtype.EntryRequirements",
        name: i18n.t('detailtype.EntryRequirements'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EntryDocumentsRequired": {
        type: "YESNOTEXT",
        i18n: "detailtype.EntryDocumentsRequired",
        name: i18n.t('detailtype.EntryDocumentsRequired'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "Transit": {
        type: "FREETEXT",
        i18n: "detailtype.Transit",
        name: i18n.t('detailtype.Transit'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuarantineUponEntry": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntry",
        name: i18n.t('detailtype.QuarantineUponEntry'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuarantineUponEntryForChildren": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntryForChildren",
        name: i18n.t('detailtype.QuarantineUponEntryForChildren'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EUDigitalCOVIDCertificateAccepted": {
        type: "YESNO",
        i18n: "detailtype.EUDigitalCOVIDCertificateAccepted",
        name: i18n.t('detailtype.EUDigitalCOVIDCertificateAccepted'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "PcrTestRequiredForEntry": {
        type: "YESNO",
        i18n: "detailtype.PcrTestRequiredForEntry",
        name: i18n.t('detailtype.PcrTestRequiredForEntry'),
        category: 'EntryRequirementsPcrTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PcrTestPresentedUponEntry": {
        type: "YESNO",
        i18n: "detailtype.PcrTestPresentedUponEntry",
        name: i18n.t('detailtype.PcrTestPresentedUponEntry'),
        category: 'EntryRequirementsPcrTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PcrTestPossibleUponEntry": {
        type: "YESNO",
        i18n: "detailtype.PcrTestPossibleUponEntry",
        name: i18n.t('detailtype.PcrTestPossibleUponEntry'),
        category: 'EntryRequirementsPcrTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PcrTestObligationForEntry": {
        type: "YESNO",
        i18n: "detailtype.PcrTestObligationForEntry",
        name: i18n.t('detailtype.PcrTestObligationForEntry'),
        category: 'EntryRequirementsPcrTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuickTestRequiredForEntry": {
        type: "YESNO",
        i18n: "detailtype.QuickTestRequiredForEntry",
        name: i18n.t('detailtype.QuickTestRequiredForEntry'),
        category: 'EntryRequirementsQuickTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuickTestPresentedUponEntry": {
        type: "YESNO",
        i18n: "detailtype.QuickTestPresentedUponEntry",
        name: i18n.t('detailtype.QuickTestPresentedUponEntry'),
        category: 'EntryRequirementsQuickTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuickTestPossibleUponEntry": {
        type: "YESNO",
        i18n: "detailtype.QuickTestPossibleUponEntry",
        name: i18n.t('detailtype.QuickTestPossibleUponEntry'),
        category: 'EntryRequirementsQuickTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuickTestObligationForEntry": {
        type: "YESNO",
        i18n: "detailtype.QuickTestObligationForEntry",
        name: i18n.t('detailtype.QuickTestObligationForEntry'),
        category: 'EntryRequirementsQuickTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TmaTestRequiredForEntry": {
        type: "YESNO",
        i18n: "detailtype.TmaTestRequiredForEntry",
        name: i18n.t('detailtype.TmaTestRequiredForEntry'),
        category: 'EntryRequirementsTmaTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TmaTestPresentedUponEntry": {
        type: "YESNO",
        i18n: "detailtype.TmaTestPresentedUponEntry",
        name: i18n.t('detailtype.TmaTestPresentedUponEntry'),
        category: 'EntryRequirementsTmaTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TmaTestPossibleUponEntry": {
        type: "YESNO",
        i18n: "detailtype.TmaTestPossibleUponEntry",
        name: i18n.t('detailtype.TmaTestPossibleUponEntry'),
        category: 'EntryRequirementsTmaTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TmaTestObligationForEntry": {
        type: "YESNO",
        i18n: "detailtype.TmaTestObligationForEntry",
        name: i18n.t('detailtype.TmaTestObligationForEntry'),
        category: 'EntryRequirementsTmaTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "LampTestRequiredForEntry": {
        type: "YESNO",
        i18n: "detailtype.LampTestRequiredForEntry",
        name: i18n.t('detailtype.LampTestRequiredForEntry'),
        category: 'EntryRequirementsLampTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "LampTestPresentedUponEntry": {
        type: "YESNO",
        i18n: "detailtype.LampTestPresentedUponEntry",
        name: i18n.t('detailtype.LampTestPresentedUponEntry'),
        category: 'EntryRequirementsLampTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "LampTestPossibleUponEntry": {
        type: "YESNO",
        i18n: "detailtype.LampTestPossibleUponEntry",
        name: i18n.t('detailtype.LampTestPossibleUponEntry'),
        category: 'EntryRequirementsLampTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "LampTestObligationForEntry": {
        type: "YESNO",
        i18n: "detailtype.LampTestObligationForEntry",
        name: i18n.t('detailtype.LampTestObligationForEntry'),
        category: 'EntryRequirementsLampTest',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TestBeforeArrivalInHours": {
        type: "POSINT",
        i18n: "detailtype.TestBeforeArrivalInHours",
        name: i18n.t('detailtype.TestBeforeArrivalInHours'),
        category: 'EntryRequirementsOthers',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "QuickTestBeforeArrivalInHours": {
        type: "POSINT",
        i18n: "detailtype.QuickTestBeforeArrivalInHours",
        name: i18n.t('detailtype.QuickTestBeforeArrivalInHours'),
        category: 'EntryRequirementsOthers',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "BeforeArrivalNotesOnTests": {
        type: "FREETEXT",
        i18n: "detailtype.BeforeArrivalNotesOnTests",
        name: i18n.t('detailtype.BeforeArrivalNotesOnTests'),
        category: 'EntryRequirementsOthers',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ExemptionFromTestingAge": {
        type: "POSINT",
        i18n: "detailtype.ExemptionFromTestingAge",
        name: i18n.t('detailtype.ExemptionFromTestingAge'),
        category: 'EntryRequirementsOthers',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ExemptionFromTestingAgeNotes": {
        type: "FREETEXT",
        i18n: "detailtype.ExemptionFromTestingAgeNotes",
        name: i18n.t('detailtype.ExemptionFromTestingAgeNotes'),
        category: 'EntryRequirementsOthers',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "VaccinationRequired": {
        type: "YESNO",
        i18n: "detailtype.VaccinationRequired",
        name: i18n.t('detailtype.VaccinationRequired'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "BanOnEntryOfUnvaccAndUnrecPersons": {
        type: "YESNO",
        i18n: "detailtype.BanOnEntryOfUnvaccAndUnrecPersons",
        name: i18n.t('detailtype.BanOnEntryOfUnvaccAndUnrecPersons'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "VaccinationBypassQuarantine": {
        type: "YESNO",
        i18n: "detailtype.VaccinationBypassQuarantine",
        name: i18n.t('detailtype.VaccinationBypassQuarantine'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "VaccinationBypassTests": {
        type: "YESNO",
        i18n: "detailtype.VaccinationBypassTests",
        name: i18n.t('detailtype.VaccinationBypassTests'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "CrossVaccinationsAccepted": {
        type: "YESNO",
        i18n: "detailtype.CrossVaccinationsAccepted",
        name: i18n.t('detailtype.CrossVaccinationsAccepted'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "VaccinationBeforeArrivalInDays": {
        type: "POSINT",
        i18n: "detailtype.VaccinationBeforeArrivalInDays",
        name: i18n.t('detailtype.VaccinationBeforeArrivalInDays'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "VaccinationProofValidInDays": {
        type: "POSINT",
        i18n: "detailtype.VaccinationProofValidInDays",
        name: i18n.t('detailtype.VaccinationProofValidInDays'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "NotesOnVaccination": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnVaccination",
        name: i18n.t('detailtype.NotesOnVaccination'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ExemptionFromVaccinationCertificateAge": {
        type: "POSINT",
        i18n: "detailtype.ExemptionFromVaccinationCertificateAge",
        name: i18n.t('detailtype.ExemptionFromVaccinationCertificateAge'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ExemptionFromVaccinationCertificateAgeNotes": {
        type: "FREETEXT",
        i18n: "detailtype.ExemptionFromVaccinationCertificateAgeNotes",
        name: i18n.t('detailtype.ExemptionFromVaccinationCertificateAgeNotes'),
        category: 'EntryRequirementsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PartialVaccinationBypassQuarantine": {
        type: "YESNO",
        i18n: "detailtype.PartialVaccinationBypassQuarantine",
        name: i18n.t('detailtype.PartialVaccinationBypassQuarantine'),
        category: 'EntryRequirementsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PartialVaccinationBypassTests": {
        type: "YESNO",
        i18n: "detailtype.PartialVaccinationBypassTests",
        name: i18n.t('detailtype.PartialVaccinationBypassTests'),
        category: 'EntryRequirementsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PartialVaccinationBeforeArrivalInDays": {
        type: "POSINT",
        i18n: "detailtype.PartialVaccinationBeforeArrivalInDays",
        name: i18n.t('detailtype.PartialVaccinationBeforeArrivalInDays'),
        category: 'EntryRequirementsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "NotesOnPartialVaccination": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnPartialVaccination",
        name: i18n.t('detailtype.NotesOnPartialVaccination'),
        category: 'EntryRequirementsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RecoveredBypassQuarantine": {
        type: "YESNO",
        i18n: "detailtype.RecoveredBypassQuarantine",
        name: i18n.t('detailtype.RecoveredBypassQuarantine'),
        category: 'EntryRequirementsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RecoveredBypassTests": {
        type: "YESNO",
        i18n: "detailtype.RecoveredBypassTests",
        name: i18n.t('detailtype.RecoveredBypassTests'),
        category: 'EntryRequirementsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RecoveredMinimumDaysPassedSinceInfection": {
        type: "POSINT",
        i18n: "detailtype.RecoveredMinimumDaysPassedSinceInfection",
        name: i18n.t('detailtype.RecoveredMinimumDaysPassedSinceInfection'),
        category: 'EntryRequirementsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RecoveredValidInDays": {
        type: "POSINT",
        i18n: "detailtype.RecoveredValidInDays",
        name: i18n.t('detailtype.RecoveredValidInDays'),
        category: 'EntryRequirementsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "NotesOnRecovered": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnRecovered",
        name: i18n.t('detailtype.NotesOnRecovered'),
        category: 'EntryRequirementsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RemovalOfExitRestriction": {
        type: "COLOR",
        i18n: "detailtype.RemovalOfExitRestriction",
        name: i18n.t('detailtype.RemovalOfExitRestriction'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RemovalOfCurfew": {
        type: "COLOR",
        i18n: "detailtype.RemovalOfCurfew",
        name: i18n.t('detailtype.RemovalOfCurfew'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "RepealCOVID19SpecialRules": {
        type: "COLOR",
        i18n: "detailtype.RepealCOVID19SpecialRules",
        name: i18n.t('detailtype.RepealCOVID19SpecialRules'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "OpeningNonVitalBusinesses": {
        type: "COLOR",
        i18n: "detailtype.OpeningNonVitalBusinesses",
        name: i18n.t('detailtype.OpeningNonVitalBusinesses'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "OpeningOfBarsRestaurants": {
        type: "COLOR",
        i18n: "detailtype.OpeningOfBarsRestaurants",
        name: i18n.t('detailtype.OpeningOfBarsRestaurants'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "OpeningOfHotels": {
        type: "COLOR",
        i18n: "detailtype.OpeningOfHotels",
        name: i18n.t('detailtype.OpeningOfHotels'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ResumptionOfEvents": {
        type: "COLOR",
        i18n: "detailtype.ResumptionOfEvents",
        name: i18n.t('detailtype.ResumptionOfEvents'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "AbolitionRestrictionSportsExercise": {
        type: "COLOR",
        i18n: "detailtype.AbolitionRestrictionSportsExercise",
        name: i18n.t('detailtype.AbolitionRestrictionSportsExercise'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "CommissioningOfPublicTransportation": {
        type: "COLOR",
        i18n: "detailtype.CommissioningOfPublicTransportation",
        name: i18n.t('detailtype.CommissioningOfPublicTransportation'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "CommissioningDomesticFlights": {
        type: "COLOR",
        i18n: "detailtype.CommissioningDomesticFlights",
        name: i18n.t('detailtype.CommissioningDomesticFlights'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "Others": {
        type: "FREETEXT",
        i18n: "detailtype.Others",
        name: i18n.t('detailtype.Others'),
        category: 'ConditionsOnSite',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EasedRestrictionsOnSiteForTested": {
        type: "YESNO",
        i18n: "detailtype.EasedRestrictionsOnSiteForTested",
        name: i18n.t('detailtype.EasedRestrictionsOnSiteForTested'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EasedRestrictionsOnSiteForRecovered": {
        type: "YESNO",
        i18n: "detailtype.EasedRestrictionsOnSiteForRecovered",
        name: i18n.t('detailtype.EasedRestrictionsOnSiteForRecovered'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EasedRestrictionsOnSiteForVaccinated": {
        type: "YESNO",
        i18n: "detailtype.EasedRestrictionsOnSiteForVaccinated",
        name: i18n.t('detailtype.EasedRestrictionsOnSiteForVaccinated'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "NotesOnEasedRestrictionsOnSite": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnEasedRestrictionsOnSite",
        name: i18n.t('detailtype.NotesOnEasedRestrictionsOnSite'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ConditionsOnSiteForChildren": {
        type: "FREETEXT",
        i18n: "detailtype.ConditionsOnSiteForChildren",
        name: i18n.t('detailtype.ConditionsOnSiteForChildren'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "PcrTestRequiredForDeparture": {
        type: "YESNO",
        i18n: "detailtype.PcrTestRequiredForDeparture",
        name: i18n.t('detailtype.PcrTestRequiredForDeparture'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "TestBeforeDepartureInHours": {
        type: "POSINT",
        i18n: "detailtype.TestBeforeDepartureInHours",
        name: i18n.t('detailtype.TestBeforeDepartureInHours'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "BeforeDepartureNotesOnTests": {
        type: "FREETEXT",
        i18n: "detailtype.BeforeDepartureNotesOnTests",
        name: i18n.t('detailtype.BeforeDepartureNotesOnTests'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "NotesOnExit": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnExit",
        name: i18n.t('detailtype.NotesOnExit'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "DepartureRegulationsForChildren": {
        type: "FREETEXT",
        i18n: "detailtype.DepartureRegulationsForChildren",
        name: i18n.t('detailtype.DepartureRegulationsForChildren'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "EntryRequirementsDE": {
        type: "FREETEXT",
        i18n: "detailtype.EntryRequirementsDE",
        name: i18n.t('detailtype.EntryRequirementsDE'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "DepartureRiskFreeAreaRki": {
        type: "YESNO",
        i18n: "detailtype.DepartureRiskFreeAreaRki",
        name: i18n.t('detailtype.DepartureRiskFreeAreaRki'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "DepartureRiskAreaRki": {
        type: "YESNO",
        i18n: "detailtype.DepartureRiskAreaRki",
        name: i18n.t('detailtype.DepartureRiskAreaRki'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "DepartureHighIncidenceAreaRki": {
        type: "YESNO",
        i18n: "detailtype.DepartureHighIncidenceAreaRki",
        name: i18n.t('detailtype.DepartureHighIncidenceAreaRki'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "DepartureVirusVariantAreaRki": {
        type: "YESNO",
        i18n: "detailtype.DepartureVirusVariantAreaRki",
        name: i18n.t('detailtype.DepartureVirusVariantAreaRki'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NoteDepartureRki": {
        type: "FREETEXT",
        i18n: "detailtype.NoteDepartureRki",
        name: i18n.t('detailtype.NoteDepartureRki'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredBeforeEntryByAirDE": {
        type: "YESNO",
        i18n: "detailtype.TestRequiredBeforeEntryByAirDE",
        name: i18n.t('detailtype.TestRequiredBeforeEntryByAirDE'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredBeforeEntryByLandAndSeaDE": {
        type: "YESNO",
        i18n: "detailtype.TestRequiredBeforeEntryByLandAndSeaDE",
        name: i18n.t('detailtype.TestRequiredBeforeEntryByLandAndSeaDE'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredBeforeEntryDEInHours": {
        type: "POSINT",
        i18n: "detailtype.TestRequiredBeforeEntryDEInHours",
        name: i18n.t('detailtype.TestRequiredBeforeEntryDEInHours'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NotesTestRequiredBeforeEntryDe": {
        type: "FREETEXT",
        i18n: "detailtype.NotesTestRequiredBeforeEntryDe",
        name: i18n.t('detailtype.NotesTestRequiredBeforeEntryDe'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "ExemptionFromTestingAgeDE": {
        type: "POSINT",
        i18n: "detailtype.ExemptionFromTestingAgeDE",
        name: i18n.t('detailtype.ExemptionFromTestingAgeDE'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "ExemptionFromTestingAgeNotesDE": {
        type: "FREETEXT",
        i18n: "detailtype.ExemptionFromTestingAgeNotesDE",
        name: i18n.t('detailtype.ExemptionFromTestingAgeNotesDE'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredAfterEntryByAirDE": {
        type: "YESNO",
        i18n: "detailtype.TestRequiredAfterEntryByAirDE",
        name: i18n.t('detailtype.TestRequiredAfterEntryByAirDE'),
        category: 'ReentryRegulationsTestsAfter',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredAfterEntryByLandAndSeaDE": {
        type: "YESNO",
        i18n: "detailtype.TestRequiredAfterEntryByLandAndSeaDE",
        name: i18n.t('detailtype.TestRequiredAfterEntryByLandAndSeaDE'),
        category: 'ReentryRegulationsTestsAfter',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "TestRequiredAfterEntryDEInHours": {
        type: "POSINT",
        i18n: "detailtype.TestRequiredAfterEntryDEInHours",
        name: i18n.t('detailtype.TestRequiredAfterEntryDEInHours'),
        category: 'ReentryRegulationsTestsAfter',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NotesTestRequiredAfterEntryDe": {
        type: "FREETEXT",
        i18n: "detailtype.NotesTestRequiredAfterEntryDe",
        name: i18n.t('detailtype.NotesTestRequiredAfterEntryDe'),
        category: 'ReentryRegulationsTestsAfter',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "QuarantineUponReentryDE": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponReentryDE",
        name: i18n.t('detailtype.QuarantineUponReentryDE'),
        category: 'ReentryRegulationsQuarantine',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "VaccinationBypassQuarantineDE": {
        type: "YESNO",
        i18n: "detailtype.VaccinationBypassQuarantineDE",
        name: i18n.t('detailtype.VaccinationBypassQuarantineDE'),
        category: 'ReentryRegulationsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "VaccinationBypassTestsDE": {
        type: "YESNO",
        i18n: "detailtype.VaccinationBypassTestsDE",
        name: i18n.t('detailtype.VaccinationBypassTestsDE'),
        category: 'ReentryRegulationsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "VaccinationBeforeEntryDEInDays": {
        type: "POSINT",
        i18n: "detailtype.VaccinationBeforeEntryDEInDays",
        name: i18n.t('detailtype.VaccinationBeforeEntryDEInDays'),
        category: 'ReentryRegulationsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NotesOnVaccinationDE": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnVaccinationDE",
        name: i18n.t('detailtype.NotesOnVaccinationDE'),
        category: 'ReentryRegulationsVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "PartialVaccinationBypassQuarantineDE": {
        type: "YESNO",
        i18n: "detailtype.PartialVaccinationBypassQuarantineDE",
        name: i18n.t('detailtype.PartialVaccinationBypassQuarantineDE'),
        category: 'ReentryRegulationsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "PartialVaccinationBypassTestsDE": {
        type: "YESNO",
        i18n: "detailtype.PartialVaccinationBypassTestsDE",
        name: i18n.t('detailtype.PartialVaccinationBypassTestsDE'),
        category: 'ReentryRegulationsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "PartialVaccinationBeforeEntryDEInDays": {
        type: "POSINT",
        i18n: "detailtype.PartialVaccinationBeforeEntryDEInDays",
        name: i18n.t('detailtype.PartialVaccinationBeforeEntryDEInDays'),
        category: 'ReentryRegulationsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NotesOnPartialVaccinationDE": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnPartialVaccinationDE",
        name: i18n.t('detailtype.NotesOnPartialVaccinationDE'),
        category: 'ReentryRegulationsPartialVaccinations',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "RecoveredBypassQuarantineDE": {
        type: "YESNO",
        i18n: "detailtype.RecoveredBypassQuarantineDE",
        name: i18n.t('detailtype.RecoveredBypassQuarantineDE'),
        category: 'ReentryRegulationsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "RecoveredBypassTestsDE": {
        type: "YESNO",
        i18n: "detailtype.RecoveredBypassTestsDE",
        name: i18n.t('detailtype.RecoveredBypassTestsDE'),
        category: 'ReentryRegulationsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "NotesOnRecoveredDE": {
        type: "FREETEXT",
        i18n: "detailtype.NotesOnRecoveredDE",
        name: i18n.t('detailtype.NotesOnRecoveredDE'),
        category: 'ReentryRegulationsRecovered',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "SevenDayIncidenceCountryCurrentWeek": {
        type: "POSINT",
        i18n: "detailtype.SevenDayIncidenceCountryCurrentWeek",
        name: i18n.t('detailtype.SevenDayIncidenceCountryCurrentWeek'),
        category: 'IncidenceStatistic',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "SevenDayIncidenceCountryLastWeek": {
        type: "POSINT",
        i18n: "detailtype.SevenDayIncidenceCountryLastWeek",
        name: i18n.t('detailtype.SevenDayIncidenceCountryLastWeek'),
        category: 'IncidenceStatistic',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "ErPassportSufficientForEntry": {
        type: "YESNO",
        i18n: "detailtype.ErPassportSufficientForEntry",
        name: i18n.t('detailtype.ErPassportSufficientForEntry'),
        category: 'ErTravelDocumentsPassport',
        showBusiness: true,
        positiveYes: true,
        isCidmer: false
    },
    
    "ErPassportExpiredPermissible": {
        type: "YESNO",
        i18n: "detailtype.ErPassportExpiredPermissible",
        name: i18n.t('detailtype.ErPassportExpiredPermissible'),
        category: 'ErTravelDocumentsPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErPassportMaxExpirationInMonthsUponEntry": {
        type: "POSINT",
        i18n: "detailtype.ErPassportMaxExpirationInMonthsUponEntry",
        name: i18n.t('detailtype.ErPassportMaxExpirationInMonthsUponEntry'),
        category: 'ErTravelDocumentsPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErPassportMinReqValidityBeyondTravelInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErPassportMinReqValidityBeyondTravelInMonths",
        name: i18n.t('detailtype.ErPassportMinReqValidityBeyondTravelInMonths'),
        category: 'ErTravelDocumentsPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErPassportMinRemainingValidityAtDepartureInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErPassportMinRemainingValidityAtDepartureInMonths",
        name: i18n.t('detailtype.ErPassportMinRemainingValidityAtDepartureInMonths'),
        category: 'ErTravelDocumentsPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempPassportSufficientForEntry": {
        type: "YESNO",
        i18n: "detailtype.ErTempPassportSufficientForEntry",
        name: i18n.t('detailtype.ErTempPassportSufficientForEntry'),
        category: 'ErTravelDocumentsTemporaryPassport',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErTempPassportExpiredPermissible": {
        type: "YESNO",
        i18n: "detailtype.ErTempPassportExpiredPermissible",
        name: i18n.t('detailtype.ErTempPassportExpiredPermissible'),
        category: 'ErTravelDocumentsTemporaryPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempPassportMaxExpirationInMonthsUponEntry": {
        type: "POSINT",
        i18n: "detailtype.ErTempPassportMaxExpirationInMonthsUponEntry",
        name: i18n.t('detailtype.ErTempPassportMaxExpirationInMonthsUponEntry'),
        category: 'ErTravelDocumentsTemporaryPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempPassportMinReqValidityBeyondTravelInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErTempPassportMinReqValidityBeyondTravelInMonths",
        name: i18n.t('detailtype.ErTempPassportMinReqValidityBeyondTravelInMonths'),
        category: 'ErTravelDocumentsTemporaryPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempPassportMinRemainingValidityAtDepartureInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErTempPassportMinRemainingValidityAtDepartureInMonths",
        name: i18n.t('detailtype.ErTempPassportMinRemainingValidityAtDepartureInMonths'),
        category: 'ErTravelDocumentsTemporaryPassport',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErIDSufficientForEntry": {
        type: "YESNO",
        i18n: "detailtype.ErIDSufficientForEntry",
        name: i18n.t('detailtype.ErIDSufficientForEntry'),
        category: 'ErTravelDocumentsID',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErIDExpiredPermissible": {
        type: "YESNO",
        i18n: "detailtype.ErIDExpiredPermissible",
        name: i18n.t('detailtype.ErIDExpiredPermissible'),
        category: 'ErTravelDocumentsID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErIDMaxExpirationInMonthsUponEntry": {
        type: "POSINT",
        i18n: "detailtype.ErIDMaxExpirationInMonthsUponEntry",
        name: i18n.t('detailtype.ErIDMaxExpirationInMonthsUponEntry'),
        category: 'ErTravelDocumentsID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErIDMinReqValidityBeyondTravelInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErIDMinReqValidityBeyondTravelInMonths",
        name: i18n.t('detailtype.ErIDMinReqValidityBeyondTravelInMonths'),
        category: 'ErTravelDocumentsID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErIDMinRemainingValidityAtDepartureInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErIDMinRemainingValidityAtDepartureInMonths",
        name: i18n.t('detailtype.ErIDMinRemainingValidityAtDepartureInMonths'),
        category: 'ErTravelDocumentsID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempIDSufficientForEntry": {
        type: "YESNO",
        i18n: "detailtype.ErTempIDSufficientForEntry",
        name: i18n.t('detailtype.ErTempIDSufficientForEntry'),
        category: 'ErTravelDocumentsTemporaryID',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErTempIDExpiredPermissible": {
        type: "YESNO",
        i18n: "detailtype.ErTempIDExpiredPermissible",
        name: i18n.t('detailtype.ErTempIDExpiredPermissible'),
        category: 'ErTravelDocumentsTemporaryID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempIDMaxExpirationInMonthsUponEntry": {
        type: "POSINT",
        i18n: "detailtype.ErTempIDMaxExpirationInMonthsUponEntry",
        name: i18n.t('detailtype.ErTempIDMaxExpirationInMonthsUponEntry'),
        category: 'ErTravelDocumentsTemporaryID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempIDMinReqValidityBeyondTravelInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErTempIDMinReqValidityBeyondTravelInMonths",
        name: i18n.t('detailtype.ErTempIDMinReqValidityBeyondTravelInMonths'),
        category: 'ErTravelDocumentsTemporaryID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTempIDMinRemainingValidityAtDepartureInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErTempIDMinRemainingValidityAtDepartureInMonths",
        name: i18n.t('detailtype.ErTempIDMinRemainingValidityAtDepartureInMonths'),
        category: 'ErTravelDocumentsTemporaryID',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenPassportSufficientForEntry": {
        type: "YESNO",
        i18n: "detailtype.ErChildrenPassportSufficientForEntry",
        name: i18n.t('detailtype.ErChildrenPassportSufficientForEntry'),
        category: 'ErTravelDocumentsChildrenPassport',
        showBusiness: false,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErChildrenPassportExpiredPermissible": {
        type: "YESNO",
        i18n: "detailtype.ErChildrenPassportExpiredPermissible",
        name: i18n.t('detailtype.ErChildrenPassportExpiredPermissible'),
        category: 'ErTravelDocumentsChildrenPassport',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenPassportMaxExpirationInMonthsUponEntry": {
        type: "POSINT",
        i18n: "detailtype.ErChildrenPassportMaxExpirationInMonthsUponEntry",
        name: i18n.t('detailtype.ErChildrenPassportMaxExpirationInMonthsUponEntry'),
        category: 'ErTravelDocumentsChildrenPassport',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenPassportMinReqValidityBeyondTravelInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErChildrenPassportMinReqValidityBeyondTravelInMonths",
        name: i18n.t('detailtype.ErChildrenPassportMinReqValidityBeyondTravelInMonths'),
        category: 'ErTravelDocumentsChildrenPassport',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenPassportMinRemainingValidityAtDepartureInMonths": {
        type: "POSINT",
        i18n: "detailtype.ErChildrenPassportMinRemainingValidityAtDepartureInMonths",
        name: i18n.t('detailtype.ErChildrenPassportMinRemainingValidityAtDepartureInMonths'),
        category: 'ErTravelDocumentsChildrenPassport',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErTravelDocumentsAdditionalRemarks": {
        type: "FREETEXT",
        i18n: "detailtype.ErTravelDocumentsAdditionalRemarks",
        name: i18n.t('detailtype.ErTravelDocumentsAdditionalRemarks'),
        category: 'ErTravelDocumentsGeneral',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErVisaRequiredForStay": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaRequiredForStay",
        name: i18n.t('detailtype.ErVisaRequiredForStay'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErVisaRequiredForTransit": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaRequiredForTransit",
        name: i18n.t('detailtype.ErVisaRequiredForTransit'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
    "ErVisaApplicationAtForeignRepresentationPossible": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaApplicationAtForeignRepresentationPossible",
        name: i18n.t('detailtype.ErVisaApplicationAtForeignRepresentationPossible'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErIssueEVisa": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErIssueEVisa",
        name: i18n.t('detailtype.ErIssueEVisa'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErIssueVisaOnArrival": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErIssueVisaOnArrival",
        name: i18n.t('detailtype.ErIssueVisaOnArrival'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: true,
        isCidmer: true
    },
    
    "ErExtensionOfStay": {
        type: "FREETEXT",
        i18n: "detailtype.ErExtensionOfStay",
        name: i18n.t('detailtype.ErExtensionOfStay'),
        category: 'ErVisaRequirement',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErCompulsoryHealthInsurance": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErCompulsoryHealthInsurance",
        name: i18n.t('detailtype.ErCompulsoryHealthInsurance'),
        category: 'ErCompulsoryHealthInsurance',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErAdditionalInformationOnEntry": {
        type: "FREETEXT",
        i18n: "detailtype.ErAdditionalInformationOnEntry",
        name: i18n.t('detailtype.ErAdditionalInformationOnEntry'),
        category: 'ErEntry',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErAdditionalInformationOnExit": {
        type: "FREETEXT",
        i18n: "detailtype.ErAdditionalInformationOnExit",
        name: i18n.t('detailtype.ErAdditionalInformationOnExit'),
        category: 'ErExit',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErDualCitizenshipNotes": {
        type: "FREETEXT",
        i18n: "detailtype.ErDualCitizenshipNotes",
        name: i18n.t('detailtype.ErDualCitizenshipNotes'),
        category: 'ErDualNationals',
        showBusiness: true,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErDualCitizenshipMinors": {
        type: "FREETEXT",
        i18n: "detailtype.ErDualCitizenshipMinors",
        name: i18n.t('detailtype.ErDualCitizenshipMinors'),
        category: 'ErRegulationsForMinors',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenAccompaniedByBothLegalGuardians": {
        type: "FREETEXT",
        i18n: "detailtype.ErChildrenAccompaniedByBothLegalGuardians",
        name: i18n.t('detailtype.ErChildrenAccompaniedByBothLegalGuardians'),
        category: 'ErRegulationsForMinors',
        showBusiness: false,
        positiveYes: false,
        isCidmer: false
    },
    
    "ErChildrenAccompaniedByOneLegalGuardian": {
        type: "FREETEXT",
        i18n: "detailtype.ErChildrenAccompaniedByOneLegalGuardian",
        name: i18n.t('detailtype.ErChildrenAccompaniedByOneLegalGuardian'),
        category: 'ErRegulationsForMinors',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenUnaccompaniedByLegalGuardians": {
        type: "FREETEXT",
        i18n: "detailtype.ErChildrenUnaccompaniedByLegalGuardians",
        name: i18n.t('detailtype.ErChildrenUnaccompaniedByLegalGuardians'),
        category: 'ErRegulationsForMinors',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "ErChildrenAdditioanInformation": {
        type: "FREETEXT",
        i18n: "detailtype.ErChildrenAdditioanInformation",
        name: i18n.t('detailtype.ErChildrenAdditioanInformation'),
        category: 'ErRegulationsForMinors',
        showBusiness: false,
        positiveYes: false,
        isCidmer: true
    },
    
    "MonkeypoxRestrictions": {
        type: "YESNOTEXT",
        i18n: "detailtype.MonkeypoxRestrictions",
        name: i18n.t('detailtype.MonkeypoxRestrictions'),
        category: 'Monkeypox',
        showBusiness: true,
        positiveYes: false,
        isCidmer: false
    },
    
}

export const generatedEntryRestrictionDetailTypesGenerated = {
    
    "RegulationsForeignOffice": {
        type: "FREETEXT",
        i18n: "detailtype.RegulationsForeignOffice",
        name: i18n.t('detailtype.RegulationsForeignOffice'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForEntry": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForEntry",
        name: i18n.t('detailtype.Covid19TestRequiredForEntry'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "VaccinationForEntryText": {
        type: "YESNOTEXT",
        i18n: "detailtype.VaccinationForEntryText",
        name: i18n.t('detailtype.VaccinationForEntryText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "PartialVaccinationForEntryText": {
        type: "YESNOTEXT",
        i18n: "detailtype.PartialVaccinationForEntryText",
        name: i18n.t('detailtype.PartialVaccinationForEntryText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "EasedEntryForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.EasedEntryForRecoveredText",
        name: i18n.t('detailtype.EasedEntryForRecoveredText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ReliefsTestedRecoveredVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.ReliefsTestedRecoveredVaccinatedText",
        name: i18n.t('detailtype.ReliefsTestedRecoveredVaccinatedText'),
        category: 'ConditionsOnSiteEasedRestrictions',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForDeparture": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForDeparture",
        name: i18n.t('detailtype.Covid19TestRequiredForDeparture'),
        category: 'DepartureRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "VaccinationForEntryDEText": {
        type: "YESNOTEXT",
        i18n: "detailtype.VaccinationForEntryDEText",
        name: i18n.t('detailtype.VaccinationForEntryDEText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: true,
    },
    
    "PartialVaccinationForEntryDEText": {
        type: "YESNOTEXT",
        i18n: "detailtype.PartialVaccinationForEntryDEText",
        name: i18n.t('detailtype.PartialVaccinationForEntryDEText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: true,
    },
    
    "EasedEntryForRecoveredDEText": {
        type: "YESNOTEXT",
        i18n: "detailtype.EasedEntryForRecoveredDEText",
        name: i18n.t('detailtype.EasedEntryForRecoveredDEText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: true,
    },
    
    "RKIAreaText": {
        type: "YESNOTEXT",
        i18n: "detailtype.RKIAreaText",
        name: i18n.t('detailtype.RKIAreaText'),
        category: 'ReentryRegulationsRKI',
        showBusiness: true,
        positiveYes: false,
    },
    
    "TestBeforeReentryDeText": {
        type: "YESNOTEXT",
        i18n: "detailtype.TestBeforeReentryDeText",
        name: i18n.t('detailtype.TestBeforeReentryDeText'),
        category: 'ReentryRegulationsTestsBefore',
        showBusiness: true,
        positiveYes: false,
    },
    
    "TestAfterReentryDeText": {
        type: "YESNOTEXT",
        i18n: "detailtype.TestAfterReentryDeText",
        name: i18n.t('detailtype.TestAfterReentryDeText'),
        category: 'ReentryRegulationsTestsAfter',
        showBusiness: true,
        positiveYes: false,
    },
    
    "SevenDayIncidenceText": {
        type: "YESNOTEXT",
        i18n: "detailtype.SevenDayIncidenceText",
        name: i18n.t('detailtype.SevenDayIncidenceText'),
        category: 'IncidenceStatistic',
        showBusiness: true,
        positiveYes: true,
    },
    
    "EntryPossibleForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.EntryPossibleForPartiallyVaccinatedText",
        name: i18n.t('detailtype.EntryPossibleForPartiallyVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "EntryPossibleForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.EntryPossibleForRecoveredText",
        name: i18n.t('detailtype.EntryPossibleForRecoveredText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "EntryPossibleForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.EntryPossibleForUnvaccAndUnrecText",
        name: i18n.t('detailtype.EntryPossibleForUnvaccAndUnrecText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: true,
    },
    
    "QuarantineUponEntryForVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntryForVaccinatedText",
        name: i18n.t('detailtype.QuarantineUponEntryForVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponEntryForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntryForPartiallyVaccinatedText",
        name: i18n.t('detailtype.QuarantineUponEntryForPartiallyVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponEntryForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntryForRecoveredText",
        name: i18n.t('detailtype.QuarantineUponEntryForRecoveredText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponEntryForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponEntryForUnvaccAndUnrecText",
        name: i18n.t('detailtype.QuarantineUponEntryForUnvaccAndUnrecText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForEntryForVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForEntryForVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredForEntryForVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForEntryForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForEntryForPartiallyVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredForEntryForPartiallyVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForEntryForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForEntryForRecoveredText",
        name: i18n.t('detailtype.Covid19TestRequiredForEntryForRecoveredText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredForEntryForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredForEntryForUnvaccAndUnrecText",
        name: i18n.t('detailtype.Covid19TestRequiredForEntryForUnvaccAndUnrecText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForEntryForVaccinatedText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForEntryForVaccinatedText",
        name: i18n.t('detailtype.NotesForEntryForVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForEntryForPartiallyVaccinatedText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForEntryForPartiallyVaccinatedText",
        name: i18n.t('detailtype.NotesForEntryForPartiallyVaccinatedText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForEntryForRecoveredText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForEntryForRecoveredText",
        name: i18n.t('detailtype.NotesForEntryForRecoveredText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesTestsForChildrenText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesTestsForChildrenText",
        name: i18n.t('detailtype.NotesTestsForChildrenText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesVaccinationCertificateForChildrenText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesVaccinationCertificateForChildrenText",
        name: i18n.t('detailtype.NotesVaccinationCertificateForChildrenText'),
        category: 'EntryRequirements',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponReentryDEForVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponReentryDEForVaccinatedText",
        name: i18n.t('detailtype.QuarantineUponReentryDEForVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponReentryDEForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponReentryDEForPartiallyVaccinatedText",
        name: i18n.t('detailtype.QuarantineUponReentryDEForPartiallyVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponReentryDEForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponReentryDEForRecoveredText",
        name: i18n.t('detailtype.QuarantineUponReentryDEForRecoveredText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "QuarantineUponReentryDEForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.QuarantineUponReentryDEForUnvaccAndUnrecText",
        name: i18n.t('detailtype.QuarantineUponReentryDEForUnvaccAndUnrecText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredBeforeReentryDEForVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredBeforeReentryDEForVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredBeforeReentryDEForVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredBeforeReentryDEForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredBeforeReentryDEForPartiallyVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredBeforeReentryDEForPartiallyVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredBeforeReentryDEForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredBeforeReentryDEForRecoveredText",
        name: i18n.t('detailtype.Covid19TestRequiredBeforeReentryDEForRecoveredText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredBeforeReentryDEForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredBeforeReentryDEForUnvaccAndUnrecText",
        name: i18n.t('detailtype.Covid19TestRequiredBeforeReentryDEForUnvaccAndUnrecText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredAfterReentryDEForVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredAfterReentryDEForVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredAfterReentryDEForVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredAfterReentryDEForPartiallyVaccinatedText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredAfterReentryDEForPartiallyVaccinatedText",
        name: i18n.t('detailtype.Covid19TestRequiredAfterReentryDEForPartiallyVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredAfterReentryDEForRecoveredText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredAfterReentryDEForRecoveredText",
        name: i18n.t('detailtype.Covid19TestRequiredAfterReentryDEForRecoveredText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "Covid19TestRequiredAfterReentryDEForUnvaccAndUnrecText": {
        type: "YESNOTEXT",
        i18n: "detailtype.Covid19TestRequiredAfterReentryDEForUnvaccAndUnrecText",
        name: i18n.t('detailtype.Covid19TestRequiredAfterReentryDEForUnvaccAndUnrecText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForReentryDEForVaccinatedText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForReentryDEForVaccinatedText",
        name: i18n.t('detailtype.NotesForReentryDEForVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForReentryDEForPartiallyVaccinatedText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForReentryDEForPartiallyVaccinatedText",
        name: i18n.t('detailtype.NotesForReentryDEForPartiallyVaccinatedText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForReentryDEForRecoveredText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForReentryDEForRecoveredText",
        name: i18n.t('detailtype.NotesForReentryDEForRecoveredText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "NotesForReentryDEForChildrenText": {
        type: "FREETEXT",
        i18n: "detailtype.NotesForReentryDEForChildrenText",
        name: i18n.t('detailtype.NotesForReentryDEForChildrenText'),
        category: 'ReentryRegulations',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErPassportText": {
        type: "FREETEXT",
        i18n: "detailtype.ErPassportText",
        name: i18n.t('detailtype.ErPassportText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErTempPassportText": {
        type: "FREETEXT",
        i18n: "detailtype.ErTempPassportText",
        name: i18n.t('detailtype.ErTempPassportText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErIDText": {
        type: "FREETEXT",
        i18n: "detailtype.ErIDText",
        name: i18n.t('detailtype.ErIDText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErTempIDText": {
        type: "FREETEXT",
        i18n: "detailtype.ErTempIDText",
        name: i18n.t('detailtype.ErTempIDText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErChildrenPassportText": {
        type: "FREETEXT",
        i18n: "detailtype.ErChildrenPassportText",
        name: i18n.t('detailtype.ErChildrenPassportText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErEntryGenerallyPermitted": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErEntryGenerallyPermitted",
        name: i18n.t('detailtype.ErEntryGenerallyPermitted'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErRequiredImmunizations": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErRequiredImmunizations",
        name: i18n.t('detailtype.ErRequiredImmunizations'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErRecommendedVaccinations": {
        type: "FREETEXT",
        i18n: "detailtype.ErRecommendedVaccinations",
        name: i18n.t('detailtype.ErRecommendedVaccinations'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErSpecialExposure": {
        type: "FREETEXT",
        i18n: "detailtype.ErSpecialExposure",
        name: i18n.t('detailtype.ErSpecialExposure'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErAddNotesOnHealth": {
        type: "FREETEXT",
        i18n: "detailtype.ErAddNotesOnHealth",
        name: i18n.t('detailtype.ErAddNotesOnHealth'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErEntryPermitRequiredForStay": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErEntryPermitRequiredForStay",
        name: i18n.t('detailtype.ErEntryPermitRequiredForStay'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErTransitVisaRequired": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErTransitVisaRequired",
        name: i18n.t('detailtype.ErTransitVisaRequired'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErEntryPermitRequiredForTransit": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErEntryPermitRequiredForTransit",
        name: i18n.t('detailtype.ErEntryPermitRequiredForTransit'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErPassportRequiredForTransit": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErPassportRequiredForTransit",
        name: i18n.t('detailtype.ErPassportRequiredForTransit'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErLocalForeignCurrencies": {
        type: "FREETEXT",
        i18n: "detailtype.ErLocalForeignCurrencies",
        name: i18n.t('detailtype.ErLocalForeignCurrencies'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErProhibitedRestrictedItems": {
        type: "FREETEXT",
        i18n: "detailtype.ErProhibitedRestrictedItems",
        name: i18n.t('detailtype.ErProhibitedRestrictedItems'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErAdditionalInformation": {
        type: "FREETEXT",
        i18n: "detailtype.ErAdditionalInformation",
        name: i18n.t('detailtype.ErAdditionalInformation'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErMedication": {
        type: "FREETEXT",
        i18n: "detailtype.ErMedication",
        name: i18n.t('detailtype.ErMedication'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "XrLocalForeignCurrencies": {
        type: "FREETEXT",
        i18n: "detailtype.XrLocalForeignCurrencies",
        name: i18n.t('detailtype.XrLocalForeignCurrencies'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "XrProhibitedRestrictedItems": {
        type: "FREETEXT",
        i18n: "detailtype.XrProhibitedRestrictedItems",
        name: i18n.t('detailtype.XrProhibitedRestrictedItems'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "XrInformationMinors": {
        type: "FREETEXT",
        i18n: "detailtype.XrInformationMinors",
        name: i18n.t('detailtype.XrInformationMinors'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "XrAdditionalInformationOnExit": {
        type: "FREETEXT",
        i18n: "detailtype.XrAdditionalInformationOnExit",
        name: i18n.t('detailtype.XrAdditionalInformationOnExit'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErBioPassportRequiredForEntry": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErBioPassportRequiredForEntry",
        name: i18n.t('detailtype.ErBioPassportRequiredForEntry'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErVisaOnArrivalOrOnlineText": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaOnArrivalOrOnlineText",
        name: i18n.t('detailtype.ErVisaOnArrivalOrOnlineText'),
        category: '',
        showBusiness: true,
        positiveYes: true,
    },
    
    "ErVisaApplicationAtForeignRepresentationRequired": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaApplicationAtForeignRepresentationRequired",
        name: i18n.t('detailtype.ErVisaApplicationAtForeignRepresentationRequired'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "ErVisaText": {
        type: "YESNOTEXT",
        i18n: "detailtype.ErVisaText",
        name: i18n.t('detailtype.ErVisaText'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiCapitalcity": {
        type: "FREETEXT",
        i18n: "detailtype.CiCapitalcity",
        name: i18n.t('detailtype.CiCapitalcity'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiLanguages": {
        type: "FREETEXT",
        i18n: "detailtype.CiLanguages",
        name: i18n.t('detailtype.CiLanguages'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiCurrency": {
        type: "FREETEXT",
        i18n: "detailtype.CiCurrency",
        name: i18n.t('detailtype.CiCurrency'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiCountryCallingCode": {
        type: "FREETEXT",
        i18n: "detailtype.CiCountryCallingCode",
        name: i18n.t('detailtype.CiCountryCallingCode'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiTippingEtiquette": {
        type: "FREETEXT",
        i18n: "detailtype.CiTippingEtiquette",
        name: i18n.t('detailtype.CiTippingEtiquette'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiCentralEmergencyNumber": {
        type: "FREETEXT",
        i18n: "detailtype.CiCentralEmergencyNumber",
        name: i18n.t('detailtype.CiCentralEmergencyNumber'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiPoliceEmergencyNumber": {
        type: "FREETEXT",
        i18n: "detailtype.CiPoliceEmergencyNumber",
        name: i18n.t('detailtype.CiPoliceEmergencyNumber'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiAmbulanceEmergencyNumber": {
        type: "FREETEXT",
        i18n: "detailtype.CiAmbulanceEmergencyNumber",
        name: i18n.t('detailtype.CiAmbulanceEmergencyNumber'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiFireBrigadeEmergencyNumber": {
        type: "FREETEXT",
        i18n: "detailtype.CiFireBrigadeEmergencyNumber",
        name: i18n.t('detailtype.CiFireBrigadeEmergencyNumber'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiDiplomaticMissions": {
        type: "FREETEXT",
        i18n: "detailtype.CiDiplomaticMissions",
        name: i18n.t('detailtype.CiDiplomaticMissions'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiNationalTourismBoard": {
        type: "FREETEXT",
        i18n: "detailtype.CiNationalTourismBoard",
        name: i18n.t('detailtype.CiNationalTourismBoard'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiInternationalDrivingPermitRequired": {
        type: "YESNOTEXT",
        i18n: "detailtype.CiInternationalDrivingPermitRequired",
        name: i18n.t('detailtype.CiInternationalDrivingPermitRequired'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiSpeedLimitInTown": {
        type: "FREETEXT",
        i18n: "detailtype.CiSpeedLimitInTown",
        name: i18n.t('detailtype.CiSpeedLimitInTown'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiSpeedLimitOutOfTown": {
        type: "FREETEXT",
        i18n: "detailtype.CiSpeedLimitOutOfTown",
        name: i18n.t('detailtype.CiSpeedLimitOutOfTown'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiSpeedLimitExpressWay": {
        type: "FREETEXT",
        i18n: "detailtype.CiSpeedLimitExpressWay",
        name: i18n.t('detailtype.CiSpeedLimitExpressWay'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiSpeedLimitFreeWay": {
        type: "FREETEXT",
        i18n: "detailtype.CiSpeedLimitFreeWay",
        name: i18n.t('detailtype.CiSpeedLimitFreeWay'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiAlcoholLimit": {
        type: "FREETEXT",
        i18n: "detailtype.CiAlcoholLimit",
        name: i18n.t('detailtype.CiAlcoholLimit'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
    "CiAdditionalInformationTraffic": {
        type: "FREETEXT",
        i18n: "detailtype.CiAdditionalInformationTraffic",
        name: i18n.t('detailtype.CiAdditionalInformationTraffic'),
        category: '',
        showBusiness: true,
        positiveYes: false,
    },
    
}

export const generatedPositiveYesDetailTypes = [
    
    "EUDigitalCOVIDCertificateAccepted",
    "CrossVaccinationsAccepted",
    "ErPassportSufficientForEntry",
    "ErTempPassportSufficientForEntry",
    "ErIDSufficientForEntry",
    "ErTempIDSufficientForEntry",
    "ErChildrenPassportSufficientForEntry",
    "ErVisaApplicationAtForeignRepresentationPossible",
    "ErIssueEVisa",
    "ErIssueVisaOnArrival",
    "VaccinationForEntryText",
    "PartialVaccinationForEntryText",
    "EasedEntryForRecoveredText",
    "VaccinationForEntryDEText",
    "PartialVaccinationForEntryDEText",
    "EasedEntryForRecoveredDEText",
    "SevenDayIncidenceText",
    "EntryPossibleForPartiallyVaccinatedText",
    "EntryPossibleForRecoveredText",
    "EntryPossibleForUnvaccAndUnrecText",
    "ErPassportText",
    "ErTempPassportText",
    "ErIDText",
    "ErTempIDText",
    "ErChildrenPassportText",
    "ErEntryGenerallyPermitted",
    "ErVisaOnArrivalOrOnlineText",
]

export const generatedNoStatusDetailTypes = [
    
    "EntryRequirements",
    "Transit",
    "BeforeArrivalNotesOnTests",
    "ExemptionFromTestingAgeNotes",
    "NotesOnVaccination",
    "ExemptionFromVaccinationCertificateAgeNotes",
    "NotesOnPartialVaccination",
    "NotesOnRecovered",
    "Others",
    "NotesOnEasedRestrictionsOnSite",
    "ConditionsOnSiteForChildren",
    "BeforeDepartureNotesOnTests",
    "NotesOnExit",
    "DepartureRegulationsForChildren",
    "EntryRequirementsDE",
    "NoteDepartureRki",
    "NotesTestRequiredBeforeEntryDe",
    "ExemptionFromTestingAgeNotesDE",
    "NotesTestRequiredAfterEntryDe",
    "NotesOnVaccinationDE",
    "NotesOnPartialVaccinationDE",
    "NotesOnRecoveredDE",
    "ErTravelDocumentsAdditionalRemarks",
    "ErExtensionOfStay",
    "ErAdditionalInformationOnEntry",
    "ErAdditionalInformationOnExit",
    "ErDualCitizenshipNotes",
    "ErDualCitizenshipMinors",
    "ErChildrenAccompaniedByBothLegalGuardians",
    "ErChildrenAccompaniedByOneLegalGuardian",
    "ErChildrenUnaccompaniedByLegalGuardians",
    "ErChildrenAdditioanInformation",
    "RegulationsForeignOffice",
    "NotesForEntryForVaccinatedText",
    "NotesForEntryForPartiallyVaccinatedText",
    "NotesForEntryForRecoveredText",
    "NotesTestsForChildrenText",
    "NotesVaccinationCertificateForChildrenText",
    "NotesForReentryDEForVaccinatedText",
    "NotesForReentryDEForPartiallyVaccinatedText",
    "NotesForReentryDEForRecoveredText",
    "NotesForReentryDEForChildrenText",
    "ErPassportText",
    "ErTempPassportText",
    "ErIDText",
    "ErTempIDText",
    "ErChildrenPassportText",
    "ErRecommendedVaccinations",
    "ErSpecialExposure",
    "ErAddNotesOnHealth",
    "ErLocalForeignCurrencies",
    "ErProhibitedRestrictedItems",
    "ErAdditionalInformation",
    "ErMedication",
    "XrLocalForeignCurrencies",
    "XrProhibitedRestrictedItems",
    "XrInformationMinors",
    "XrAdditionalInformationOnExit",
    "CiCapitalcity",
    "CiLanguages",
    "CiCurrency",
    "CiCountryCallingCode",
    "CiTippingEtiquette",
    "CiCentralEmergencyNumber",
    "CiPoliceEmergencyNumber",
    "CiAmbulanceEmergencyNumber",
    "CiFireBrigadeEmergencyNumber",
    "CiDiplomaticMissions",
    "CiNationalTourismBoard",
    "CiSpeedLimitInTown",
    "CiSpeedLimitOutOfTown",
    "CiSpeedLimitExpressWay",
    "CiSpeedLimitFreeWay",
    "CiAlcoholLimit",
    "CiAdditionalInformationTraffic",
]

export const generatedEntryRestrictionDetailChildrenDetails = [
    "NotesForEntryForForChildrenText",
    "NotesForReentryDEForChildrenText",
    "ConditionsOnSiteForChildren",
    "ConditionsOnSiteForChildren",
    "QuarantineUponEntryForChildren",
    "NotesTestsForChildrenText",
    "NotesVaccinationCertificateForChildrenText",
    "ErChildrenPassportText",
    "ErDualCitizenshipMinors",
    "ErChildrenAccompaniedByBothLegalGuardians",
    "ErChildrenAccompaniedByOneLegalGuardian",
    "ErChildrenUnaccompaniedByLegalGuardians",
    "ErChildrenAdditioanInformation",
    "DepartureRegulationsForChildren",
    
]

export const generatedEntryRestrictionDetailDisplayMainCategories = {
    
    entry_requirements : {
        'i18n': 'detailmaincategory.EntryRequirements',
        'name': i18n.t('detailmaincategory.EntryRequirements'),
    },
    
    entry : {
        'i18n': 'detailmaincategory.Entry',
        'name': i18n.t('detailmaincategory.Entry'),
    },
    
    return_trip_covid19 : {
        'i18n': 'detailmaincategory.ReturnTripCovid19',
        'name': i18n.t('detailmaincategory.ReturnTripCovid19'),
    },
    
    return_trip : {
        'i18n': 'detailmaincategory.ReturnTrip',
        'name': i18n.t('detailmaincategory.ReturnTrip'),
    },
    
    enduser_entry_requirements : {
        'i18n': 'detailmaincategory.EntryRequirements',
        'name': i18n.t('detailmaincategory.EntryRequirements'),
    },
    
    enduser_vaccination_status_and_entry : {
        'i18n': 'detailmaincategory.VaccinationStatusAndEntry',
        'name': i18n.t('detailmaincategory.VaccinationStatusAndEntry'),
    },
    
    enduser_minors_and_dual_nationals : {
        'i18n': 'detailmaincategory.MinorsAndDualNationals',
        'name': i18n.t('detailmaincategory.MinorsAndDualNationals'),
    },
    
    enduser_local_covid19_restrictions : {
        'i18n': 'detailmaincategory.LocalCovid19Restrictions',
        'name': i18n.t('detailmaincategory.LocalCovid19Restrictions'),
    },
    
    enduser_health_information : {
        'i18n': 'detailmaincategory.HealthInformation',
        'name': i18n.t('detailmaincategory.HealthInformation'),
    },
    
    enduser_departure : {
        'i18n': 'detailmaincategory.Departure',
        'name': i18n.t('detailmaincategory.Departure'),
    },
    
    enduser_destination_details : {
        'i18n': 'detailmaincategory.MoreAboutDestination',
        'name': i18n.t('detailmaincategory.MoreAboutDestination'),
    },
    
}

export const generatedEntryRestrictionDetailDisplayGroupProfessional = [

    {
        'key': 'er_entry_requirements',
        'i18n': 'detailgroup.ErEntryRequirements',
        'name': i18n.t('detailgroup.ErEntryRequirements'),
        'details': [
            'ErEntryGenerallyPermitted',
            'ErAdditionalInformationOnEntry',
            
        ]
    },

    {
        'key': 'er_customs_and_import',
        'i18n': 'detailgroup.ErCustomsAndImport',
        'name': i18n.t('detailgroup.ErCustomsAndImport'),
        'details': [
            'ErLocalForeignCurrencies',
            'ErProhibitedRestrictedItems',
            'ErMedication',
            'ErAdditionalInformation',
            
        ]
    },

    {
        'key': 'xr_exit_requirements',
        'i18n': 'detailgroup.XrExitRequirements',
        'name': i18n.t('detailgroup.XrExitRequirements'),
        'details': [
            'XrLocalForeignCurrencies',
            'XrProhibitedRestrictedItems',
            'XrAdditionalInformationOnExit',
            'XrInformationMinors',
            
        ]
    },

    {
        'key': 'er_required_travel_documents',
        'i18n': 'detailgroup.ErRequiredTravelDocuments',
        'name': i18n.t('detailgroup.ErRequiredTravelDocuments'),
        'details': [
            'ErPassportText',
            'ErTempPassportText',
            'ErIDText',
            'ErTempIDText',
            'ErChildrenPassportText',
            'ErTravelDocumentsAdditionalRemarks',
            
        ]
    },

    {
        'key': 'er_visa',
        'i18n': 'detailgroup.ErVisa',
        'name': i18n.t('detailgroup.ErVisa'),
        'details': [
            'ErVisaRequiredForStay',
            'ErEntryPermitRequiredForStay',
            'ErVisaApplicationAtForeignRepresentationRequired',
            'ErIssueEVisa',
            'ErIssueVisaOnArrival',
            'ErExtensionOfStay',
            
        ]
    },

    {
        'key': 'er_documents_for_transit',
        'i18n': 'detailgroup.ErDocumentsForTransit',
        'name': i18n.t('detailgroup.ErDocumentsForTransit'),
        'details': [
            'ErTransitVisaRequired',
            'ErEntryPermitRequiredForTransit',
            'ErPassportRequiredForTransit',
            
        ]
    },

    {
        'key': 'er_health_insurance',
        'i18n': 'detailgroup.ErHealthInsurance',
        'name': i18n.t('detailgroup.ErHealthInsurance'),
        'details': [
            'ErCompulsoryHealthInsurance',
            'ErRequiredImmunizations',
            'ErRecommendedVaccinations',
            'ErSpecialExposure',
            'ErAddNotesOnHealth',
            
        ]
    },

    {
        'key': 'er_dual_nationals',
        'i18n': 'detailgroup.ErDualNationals',
        'name': i18n.t('detailgroup.ErDualNationals'),
        'details': [
            'ErDualCitizenshipNotes',
            
        ]
    },

    {
        'key': 'er_children',
        'i18n': 'detailgroup.ErChildren',
        'name': i18n.t('detailgroup.ErChildren'),
        'details': [
            'ErDualCitizenshipMinors',
            'ErChildrenAccompaniedByOneLegalGuardian',
            'ErChildrenUnaccompaniedByLegalGuardians',
            'ErChildrenAdditioanInformation',
            
        ]
    },

]

export const generatedEntryRestrictionDetailDisplayGroupEnduser = [

    {
        'key': 'er_entry_requirements',
        'i18n': 'detailgroup.EnduserErEntryRequirements',
        'name': i18n.t('detailgroup.EnduserErEntryRequirements'),
        'details': [
            'ErEntryGenerallyPermitted',
            'ErAdditionalInformationOnEntry',
            
        ]
    },

    {
        'key': 'er_required_travel_documents',
        'i18n': 'detailgroup.EnduserErRequiredTravelDocuments',
        'name': i18n.t('detailgroup.EnduserErRequiredTravelDocuments'),
        'details': [
            'ErVisaText',
            'ErEntryPermitRequiredForStay',
            'ErEntryDocumentsText',
            'ErTravelDocumentsAdditionalRemarks',
            'ErExtensionOfStay',
            
        ]
    },

    {
        'key': 'er_documents_for_transit',
        'i18n': 'detailgroup.EnduserErDocumentsForTransit',
        'name': i18n.t('detailgroup.EnduserErDocumentsForTransit'),
        'details': [
            'ErTransitVisaRequired',
            'ErEntryPermitRequiredForTransit',
            'ErPassportRequiredForTransit',
            
        ]
    },

    {
        'key': 'er_customs_and_import',
        'i18n': 'detailgroup.EnduserErCustomsAndImport',
        'name': i18n.t('detailgroup.EnduserErCustomsAndImport'),
        'details': [
            'ErLocalForeignCurrencies',
            'ErProhibitedRestrictedItems',
            'ErMedication',
            'ErAdditionalInformation',
            
        ]
    },

    {
        'key': 'er_children',
        'i18n': 'detailgroup.EnduserErChildren',
        'name': i18n.t('detailgroup.EnduserErChildren'),
        'details': [
            'ErChildrenAccompaniedByOneLegalGuardian',
            'ErChildrenUnaccompaniedByLegalGuardians',
            'ErChildrenAdditioanInformation',
            
        ]
    },

    {
        'key': 'er_dual_nationals',
        'i18n': 'detailgroup.EnduserErDualNationals',
        'name': i18n.t('detailgroup.EnduserErDualNationals'),
        'details': [
            'ErDualCitizenshipNotes',
            'ErDualCitizenshipMinors',
            
        ]
    },

    {
        'key': 'vaccinations',
        'i18n': 'detailgroup.Vaccinations',
        'name': i18n.t('detailgroup.Vaccinations'),
        'details': [
            'ErRequiredImmunizations',
            'ErRecommendedVaccinations',
            'ErSpecialExposure',
            
        ]
    },

    {
        'key': 'health_insurance',
        'i18n': 'detailgroup.HealthInsurance',
        'name': i18n.t('detailgroup.HealthInsurance'),
        'details': [
            'ErCompulsoryHealthInsurance',
            'ErAddNotesOnHealth',
            
        ]
    },

    {
        'key': 'xr_exit_requirements',
        'i18n': 'detailgroup.EnduserXrExitRequirements',
        'name': i18n.t('detailgroup.EnduserXrExitRequirements'),
        'details': [
            'XrLocalForeignCurrencies',
            'XrProhibitedRestrictedItems',
            'XrAdditionalInformationOnExit',
            'XrInformationMinors',
            
        ]
    },

    {
        'key': 'worth_knowing',
        'i18n': 'detailgroup.EnduserWorthKnowing',
        'name': i18n.t('detailgroup.EnduserWorthKnowing'),
        'details': [
            'CiCapitalcity',
            'CiLanguages',
            'CiCurrency',
            'CiCountryCallingCode',
            'CiTippingEtiquette',
            
        ]
    },

    {
        'key': 'case_of_emergency',
        'i18n': 'detailgroup.CaseOfEmergency',
        'name': i18n.t('detailgroup.CaseOfEmergency'),
        'details': [
            'CiCentralEmergencyNumber',
            'CiPoliceEmergencyNumber',
            'CiAmbulanceEmergencyNumber',
            'CiFireBrigadeEmergencyNumber',
            
        ]
    },

    {
        'key': 'on_site_representatives',
        'i18n': 'detailgroup.OnSiteRepresentatives',
        'name': i18n.t('detailgroup.OnSiteRepresentatives'),
        'details': [
            'CiDiplomaticMissions',
            'CiNationalTourismBoard',
            
        ]
    },

    {
        'key': 'traffic',
        'i18n': 'detailgroup.Traffic',
        'name': i18n.t('detailgroup.Traffic'),
        'details': [
            'CiInternationalDrivingPermitRequired',
            'CiSpeedLimitInTown',
            'CiSpeedLimitOutOfTown',
            'CiSpeedLimitExpressWay',
            'CiSpeedLimitFreeWay',
            'CiAlcoholLimit',
            'CiAdditionalInformationTraffic',
            
        ]
    },

]

export const generatedEntryRestrictionDetailDisplayGroupBasic = [

    {
        'key': 'regulations_foreign_office',
        'i18n': 'detailgroup.PriorToTravel',
        'name': i18n.t('detailgroup.PriorToTravel'),
        'details': [
            'RegulationsForeignOffice',
            
        ]
    },

    {
        'key': 'entry_requirements',
        'i18n': 'detailgroup.EntryRequirements',
        'name': i18n.t('detailgroup.EntryRequirements'),
        'details': [
            'EntryRequirements',
            'EntryDocumentsRequired',
            'Transit',
            'Covid19TestRequiredForEntry',
            'QuarantineUponEntry',
            'VaccinationRequired',
            'NotesForEntryForVaccinatedText',
            'VaccinationForEntryText',
            'CrossVaccinationsAccepted',
            'PartialVaccinationForEntryText',
            'EasedEntryForRecoveredText',
            
        ]
    },

    {
        'key': 'conditions_on_site',
        'i18n': 'detailgroup.ConditionsOnSite',
        'name': i18n.t('detailgroup.ConditionsOnSite'),
        'details': [
            'ReliefsTestedRecoveredVaccinatedText',
            'SevenDayIncidenceText',
            'RemovalOfExitRestriction',
            'RemovalOfCurfew',
            'RepealCOVID19SpecialRules',
            'OpeningNonVitalBusinesses',
            'OpeningOfBarsRestaurants',
            'OpeningOfHotels',
            'ResumptionOfEvents',
            'AbolitionRestrictionSportsExercise',
            'CommissioningOfPublicTransportation',
            'CommissioningDomesticFlights',
            'Others',
            
        ]
    },

    {
        'key': 'departure_regulations',
        'i18n': 'detailgroup.Departure',
        'name': i18n.t('detailgroup.Departure'),
        'details': [
            'Covid19TestRequiredForDeparture',
            'NotesOnExit',
            
        ]
    },

    {
        'key': 'reentry_regulations',
        'i18n': 'detailgroup.Reentry',
        'name': i18n.t('detailgroup.Reentry'),
        'details': [
            'EntryRequirementsDE',
            'RKIAreaText',
            'TestBeforeReentryDeText',
            'TestAfterReentryDeText',
            'QuarantineUponReentryDE',
            'VaccinationForEntryDEText',
            'NotesForReentryDEForPartiallyVaccinatedText',
            'PartialVaccinationForEntryDEText',
            'EasedEntryForRecoveredDEText',
            
        ]
    },

]