import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { BOSYS_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: BOSYS_QUERY,
    fetchAllFieldName: 'bosysQuery',
  },
})
/* eslint-enable no-unused-vars */

export default store

