import gql from 'graphql-tag'

export const BOSYS_QUERY = gql`
query BosysQuery($gmUniqueEventId: String!) {
  bosysQuery(gmUniqueEventId: $gmUniqueEventId) {
    results {
      booking {
        gmUniqueEventId
        organisation {
          id
          name
        }
        bosysTravelId
        bosysCalltoken
        travelStart
        travelEnd
        travelerCount
        touroperatorCode
      }
      url
    }
  }
}
`