import axios from 'axios';

const instance = axios.create();
instance.interceptors.request.use(function (config) {
  const authToken = localStorage.getItem("authToken");

  config.headers['Authorization'] = authToken ? `JWT ${authToken}` : "no token";
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default instance;
