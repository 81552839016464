import gql from 'graphql-tag'

export const LOOKUP_DESTINATIONS = gql`
query LookupDestinations($search: String!){
  lookupDestinations(search: $search){
  destinations {
      destinationType
      id
      name
    }
  }
}
`

export const LOOKUP_COUNTRIES = gql`
query LookupCountries($search: String!){
  lookupCountries(search: $search) {
    countries {
      destinationType
      id
      name
    }
  }
}
`

export const DESTINATION_DETAIL = gql`
query DestinationDetail(
    $departureType: String!,
    $departureId: ID!,  
    $destinationType: String!,
    $destinationId: ID!,
    $citizenshipCountryId: ID!
    $restrictionType: String!,
    $dateStart: Date!,
    $dateEnd: Date!,

    $transitType: String,
    $transitId: ID,
  ) {
  destinationDetail(
      departureType: $departureType,
      departureId: $departureId,
      destinationType: $destinationType,
      destinationId: $destinationId,
      restrictionType: $restrictionType,
      citizenshipCountryId: $citizenshipCountryId,
      dateStart: $dateStart,
      dateEnd: $dateEnd,
      transitType: $transitType,
      transitId: $transitId,      
  ){
    departureType
    departureId
    destinationType
    destinationId
    transitType
    transitId
    citizenshipCountryId
    name
    iso2
    iata
    dateStart
    dateEnd
    restrictionType,
    statusDestination,
    statusRki    
    countryRiskCategories {
      id
      level
      riskCategory {
        id
        code
        title
      }
    }
    events {
      category {
        code
        name
      }
      id
      level
      title
      staticPageUrl
      significantUpdateAt
    }
    deviations {
      destinationType
      id
      iata
      name
    }
    items {
      name
      detailType
      status
      value
      note
    }
    content {
      key
      name
      mainCategory
      details {
        name
        detailType
        status
        value
        note
      }
    }
    destinationCountry {
      id
      iso2
      name
    }
    transitCountry {
      id
      iso2
      name
    }
  }
}
`

export const SENDENTRYRESTRICTIONFEEDBACK_MUTATION = gql`
mutation SendEntryrestrictionFeedback($input: SendEntryRestrictionFeedbackInputType!) {
  sendEntryrestrictionFeedback(input: $input){
    ok
  }
}
`