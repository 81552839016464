import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { COUNTRIES_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: COUNTRIES_QUERY,
    fetchAllFieldName: 'countries',
  },
  actions: {
    /**
     * Add sorting
     */
    extractFetchAllResultEntries({ state, commit, rootState }, result) {
      let entries = result.data[state.settings.fetchAllFieldName].results
      entries = entries.sort((a,b) => a.nameShort.localeCompare(b.nameShort));
      return entries
    },
  }
})
/* eslint-enable no-unused-vars */

export default store

