import gql from 'graphql-tag'

export const TOUROPERATOREXTERNALRESTRICTIONINFOS_QUERY = gql`
query TouroperatorExternalRestrictionInfos {
  touroperatorexternalrestrictioninfos {
    results {
      id
      name
      infoCategory
      link
      linkDe
      linkEn
      allowIframe
    }
  }
}
`
