import gql from 'graphql-tag'

import { COUNTRY_FRAGMENT } from "../../graphql";

export const COUNTRIES_QUERY = gql`
query Countries {
  countries {
    results {
    ...CountryFragment
    }
  }
}
${COUNTRY_FRAGMENT}
`