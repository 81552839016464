import gql from 'graphql-tag'

import { ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT } from '../../graphql'

export const ENTRYRESTRICTION_QUERY = gql`
query EntryRestrictionQuery($id: ID!) {
  entryRestriction(id: $id) {
    ...EntryRestrictionWithRelationsFragment
    reminder
    internalNote
    internalSources
    modifiedBy {
      id
      firstName
      lastName
    }
    significantUpdateBy {
      id
      firstName
      lastName
    }
    checkedAt
    checkedBy {
      id
      firstName
      lastName
    }
  }
}
${ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT}
`

export const ENTRYRESTRICTION_CREATE_MUTATION = gql`
mutation CreateEntryRestriction($input: EntryRestrictionCreateGenericType!) {
  createEntryrestriction(input: $input) {
    entryrestriction {
      ...EntryRestrictionWithRelationsFragment
      reminder
      internalNote
      internalSources
      regionDeviationTourism
      regionDeviationBusiness
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateBy {
        id
        firstName
        lastName
      }
      checkedAt
      checkedBy {
        id
        firstName
        lastName
      }
    }
    ok
    errors {
      field
      messages
    }
  }
}
${ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT}
`

export const ENTRYRESTRICTION_UPDATE_MUTATION = gql`
mutation UpdateEntryRestriction($input: EntryRestrictionUpdateGenericType!) {
  updateEntryrestriction(input: $input) {
    entryrestriction {
      ...EntryRestrictionWithRelationsFragment
      reminder
      internalNote
      internalSources
      regionDeviationTourism
      regionDeviationBusiness
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateBy {
        id
        firstName
        lastName
      }
      checkedAt
      checkedBy {
        id
        firstName
        lastName
      }
    }
    ok
    errors {
      field
      messages
    }
  }
}
${ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT}
`