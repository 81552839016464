import gql from 'graphql-tag'

export const GMEVENTCOVIDUPDATE_CREATE_MUTATION = gql`
mutation CreateGmEventCovidUpdate($input: CreateGmEventCovidUpdateInputType!) {
  createGmEventCovidUpdate(input: $input) {
    gmEventCovidUpdate {
      id
    }
    ok
  }
}
`
