import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { ENTRYRESTRICTIONS_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: ENTRYRESTRICTIONS_QUERY,
    fetchAllFieldName: 'entryRestrictions',
  },
})
/* eslint-enable no-unused-vars */

export default store

