import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { TEXTSNIPPETS_QUERY, TEXTSNIPPETS_CREATE_MUTATION, TEXTSNIPPETS_UPDATE_MUTATION, TEXTSNIPPETS_DELETE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: TEXTSNIPPETS_QUERY,
    fetchAllFieldName: 'textsnippets',

    createMutation: TEXTSNIPPETS_CREATE_MUTATION,
    createMutationName: 'createTextsnippet',
    createFieldName: 'textsnippet',

    updateMutation: TEXTSNIPPETS_UPDATE_MUTATION,
    updateMutationName: 'updateTextsnippet',
    updateFieldName: 'textsnippet',

    deleteMutation: TEXTSNIPPETS_DELETE_MUTATION,
    deleteMutationName: 'deleteTextsnippet',
    deleteFieldName: 'textsnippet',
  },
})
/* eslint-enable no-unused-vars */

export default store

