import gql from 'graphql-tag'

import { COUNTRY_FRAGMENT, REGION_FRAGMENT, AIRPORT_FRAGMENT, ENTRYRESTRICTION_FRAGMENT, ENTRYRESTRICTIONDETAIL_FRAGMENT } from "../../graphql";

/**
 * A minimized EntryRestriction, as it does not contain the authority, because the additional layer slowed down the query by the factor of
 * 20 times!
 */
export const ENTRYRESTRICTION_WITHRELATIONS_WITHOUT_AUTHORITY_FRAGMENT = `
fragment EntryRestrictionFragmentWithRelationsWithoutAuthorityFragment on EntryRestrictionGenericType {
  ...EntryRestrictionFragment
  reminder
  internalNote
  internalSources
  regionDeviationTourism
  regionDeviationBusiness
  country {
    id
  }
  region {
    ...RegionFragment
  }
  airport {
    ...AirportFragment
  }
  modifiedBy {
    id
    firstName
    lastName
  }
  significantUpdateBy {
    id
    firstName
    lastName
  }
  checkedAt
  checkedBy {
    id
    firstName
    lastName
  }
}
${ENTRYRESTRICTION_FRAGMENT}
${COUNTRY_FRAGMENT}
${REGION_FRAGMENT}
${AIRPORT_FRAGMENT}
`

/* A heavy query that needs to load all countries and all entry restrictions.
 * We use two separate queries, as one big query would make the django querybuilder cry... or better:
 * REALLY slow, as the multiple levels would make the request very slow.
 *
 * We could use a different query for the countries, but it would only help when the countries are already
 * loaden, plus it takes only a couple of milliseconds to load the countries and would turn a simple
 * request to a solution handling multiple loading states. Not good.
 *
 * With this query, we use the country list as foundation and create the multiple levels on the client side.
 * As we want to show EVERY country in the list, even one without any EntryRestrictions, we need the full list
 * for that. But for Regions and Airports, we only want the ones that have EntryRestrictions, so we load them
 * together with the restrictions.
 */
export const COUNTRIES_ENTRYRESTRICTIONS_QUERY = gql`
query EntryRestrictionFragmentWithLessRelations {
  entryRestrictions(isActive: true){
    results {
      ...EntryRestrictionFragmentWithRelationsWithoutAuthorityFragment
    }
  }
  countries {
    results {
      ...CountryFragment
    }
  }
}

${COUNTRY_FRAGMENT}
${ENTRYRESTRICTION_WITHRELATIONS_WITHOUT_AUTHORITY_FRAGMENT}
`