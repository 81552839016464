<template>
  <v-menu
    v-model="menu"
    :offsetY="true"
    z-index="1010"
  >
    <template v-slot:activator="{ on }">
      <v-avatar v-if="user" color="indigo" size="28" v-on="on">
        <v-icon small dark>account_circle</v-icon>
      </v-avatar>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="indigo" size="36">
              <v-icon dark>account_circle</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.firstName }} {{ user.lastName }} ({{ user.username }})</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn color="primary" :to="{name: 'profile-edit'}" text small>{{ $t("main.CUSTOMIZE_ACTION") }}</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <div class="component-content">
        <LanguageSelector />
      </div>


      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-if="$hasPermission('a3m_core.DestinationManager')"
          :to="{name: 'organisation-countries-selection'}"
        >
          <v-list-item-title>{{ $t("main.COUNTRY_SELECTION") }}</v-list-item-title>
        </v-list-item>

        <v-list-item
             v-if="$hasPermission('a3m_core.DestinationManager')"
            :to="{name: 'mytrip-filter-settings'}"
        >
          <v-list-item-title>{{ $t("main.MYJOURNEY_PAGE") }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <span class="version-key ma-2">Version: {{ versionKey }}</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="ma-2" small @click="logout">{{ $t("main.LOGOUT_ACTION") }}</v-btn>
      </v-card-actions>
    </v-card>

  </v-menu>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LanguageSelector from '@/components/ui/LanguageSelector'

export default {
  name: 'ToolbarAvatar',
  components: {
    LanguageSelector
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
    }),
    ...mapGetters({
      versionKey: 'authStore/versionKey'
    })
  },
  methods: {
    ...mapActions({
      logout: 'authStore/logout'
    })
  }
};
</script>

<style scoped>
  .v-avatar {
    cursor: pointer;
  }

  .version-key {
    font-size: 60%;
    color: gray;
  }

  .component-content {
    padding: 0.5rem 1rem;
  }
</style>