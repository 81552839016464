import gql from 'graphql-tag'

export const EVENTS_QUERY = gql`
query EventsLatestSignificantUpdatesPinned {
  eventsLatestSignificantUpdatesPinned {
    results {
      id
      event{
        category {
          name
          code
        }
        country {
          iso2
          nameShort
        }
        title
        isActive
        level
        significantUpdateAt
        staticPageUrl
        timeEnd
        timeStart
        countUpdates
        gmUniqueEventId
      }
      travelersNow
      travelersPotentiallyAffected
    }
  }
}
`

export const PIN_EVENT_MUTATION = gql`
mutation PinEvent($input: PinEventInputType!) {
  pinEvent(input: $input) {
    ok
  }
}
`

export const UNPIN_EVENT_MUTATION = gql`
mutation UnpinEvent($input: UnpinEventInputType!) {
  unpinEvent(input: $input) {
    ok
  }
}
`