import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { NEWS_QUERY, NEWS_CREATE_MUTATION, NEWS_UPDATE_MUTATION, NEWS_DELETE_MUTATION} from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: NEWS_QUERY,
    fetchAllFieldName: 'news',

    createMutation: NEWS_CREATE_MUTATION,
    createMutationName: 'createNews',
    createFieldName: 'news',

    updateMutation: NEWS_UPDATE_MUTATION,
    updateMutationName: 'updateNews',
    updateFieldName: 'news',

    deleteMutation: NEWS_DELETE_MUTATION,
    deleteMutationName: 'deleteNews',
    deleteFieldName: 'news',
  },
  getters: {
    newsWithIsUnread(state, getters, rootState) {
      let news = []
      for (let entry of state.entries) {
        entry.isUnread = false
        if (moment(entry.createdAt) > rootState.uiStore.lastNewsVisitDate) {
          entry.isUnread = true
        }
        news.push(entry)
      }
      return news
    },

    breakingNews(state, getters, rootState) {
      let breakingNews = []

      let newsList = getters.newsWithIsUnread.filter(entry => entry.newsType == 'GENERIC')

      for (let news of newsList) {
        if (!news.importantUntil) {
          continue
        }

        let importantUntilDate = moment(news.importantUntil)
        if (importantUntilDate > moment().subtract({days: 1})) {
          breakingNews.push(news)
        }
      }

      return breakingNews
    },

    oldBreakingNews(state, getters, rootState) {
      let oldBreakingNews = []
      let newsList = getters.newsWithIsUnread.filter(entry => entry.newsType == 'GENERIC')

      for (let news of newsList) {
        if (!news.importantUntil) {
          oldBreakingNews.push(news)
        } else {
          let importantUntilDate = moment(news.importantUntil)
          if (importantUntilDate < moment().startOf('day')) {
            oldBreakingNews.push(news)
          }
        }
      }
      return oldBreakingNews
    },

    destinationManagerNews(state, getters, rootState) {
      return getters.newsWithIsUnread.filter(entry => entry.newsType == 'DESTINATIONMANAGER')
    }
  }
})
/* eslint-enable no-unused-vars */

export default store

