import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLogin from '@/views/AppLogin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: AppLogin,
    meta: {
      omitAuth: true // login page doesn't need a login check!
    }
  },

  {
    path: '/', redirect: { name: 'index' }
  }, {
    path: '/newsboard',
    name: "index",
    component: () => import(/* webpackChunkName: "maps" */ '@/views/IndexView.vue'),
  },

  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/EditProfileView.vue'),
  },

  {
    path: '/map',
    name: 'destination-map',
    component: () => import(/* webpackChunkName: "maps" */ '@/views/DestinationMapView.vue'),
  },

  {
    path: '/entryrestrictions',
    name: 'entryrestrictions',
    component: () => import(/* webpackChunkName: "entryrestrictions" */ '@/views/EntryRestriction/EntryRestrictionsView.vue'),
  },{
    path: '/entryrestrictions/:entryRestrictionId/edit',
    name: 'entryrestriction-edit',
    component: () => import(/* webpackChunkName: "entryrestrictions" */ '@/views/EntryRestriction/EntryRestrictionEditView.vue'),
  },

  {
    path: '/informationcenter',
    name: 'informationcenter',
    component: () => import(/* webpackChunkName: "informationcenter" */ '@/views/InformationCenter.vue'),
  },

  {
    path: '/mytrip-history',
    name: 'mytrip-history',
    component: () => import(/* webpackChunkName: "mytrip" */ '@/views/MyJourneyHistory.vue'),
  },

  {
    path: '/news-admin/list',
    name: 'news-admin-list',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminList.vue'),
  }, {
    path: '/news-admin/edit/:newsId',
    name: 'news-admin-edit',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminEdit.vue'),
  }, {
    path: '/news-admin/add/',
    name: 'news-admin-add',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminEdit.vue'),
  },

  {
    path: '/organisation/countries-selection',
    name: 'organisation-countries-selection',
    component: () => import(/* webpackChunkName: "organisation" */ '@/views/Organisation/CountrySelectionView.vue'),
  },

  {
    path: '/mytrip/filter-settings',
    name: 'mytrip-filter-settings',
    component: () => import(/* webpackChunkName: "organisation" */ '@/views/Organisation/MyTripFilterSettingsView.vue'),
  },

  {
    path: '/textsnippets/list',
    name: 'textsnippet-list',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetList.vue'),
  }, {
    path: '/textsnippets/edit/:textSnippetId',
    name: 'textsnippet-edit',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetEdit.vue'),
  }, {
    path: '/textsnippets/add/',
    name: 'textsnippet-add',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetEdit.vue'),
  },

  {
    path: '/destination',
    name: 'destination',
    component: () => import(/* webpackChunkName: "destination" */ '@/views/Destination/DestinationView.vue'),
    children: [
      {
        path: '/destination',
        name: 'destination-empty',
        meta: {
          mainMenu: "destination",
        },
        component: () => import(/* webpackChunkName: "destination" */ '@/views/Destination/DestinationEmptyView.vue'),
      }, {
        path: '/destination/:departureType/:departureId/:destinationType/:destinationId/:transitType/:transitId/:citizenshipCountryId/:restrictionType/:dateStart/:dateEnd',
        name: 'destination-result',
        meta: {
          mainMenu: "destination",
        },
        component: () => import(/* webpackChunkName: "destination" */ '@/views/Destination/DestinationResultView.vue'),
      }
    ]
  },

  {
    path: '/internal/entryrestrictionoverview/',
    name: 'internal-entryrestriction-overview',
    component: () => import(/* webpackChunkName: "internal" */ '@/views/Internal/EntryRestrictionOverview.vue'),
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // We do an auth check for all pages except the ones with omitAuth = True
  // like the login page
  if (!to.matched.some(record => record.meta.omitAuth)) {
    if (localStorage.getItem("authToken")) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
