import { BaseRepository } from './baseRepository'
import { MYJOURNEY_CREATE_MUTATION, MYJOURNEY_LIST_QUERY } from './myJourneyRepository.graphql'

export class MyJourneyRepository extends BaseRepository {

  async createMyJourney(
      departureType, 
      departureId,
      destinations,
      citizenshipCountryIds,
      dateStart,
      dateEnd,
      restrictionType,
      language,
      email,
      showEvents,
      internalNote,
      transitType,
      transitId
  ) {
    let input = {
      departureType,
      departureId,
      destinations,
      citizenshipCountryIds,
      dateStart,
      dateEnd,
      restrictionType,
      language,
      email,
      showEvents,
      internalNote,
      transitType,
      transitId
    }

    return await this.query(
      MYJOURNEY_CREATE_MUTATION, {
        input: input,
      },
      'createMyjourney'
    )
  }

  async getMyJourneyList(limit, offset, search) {
    return await this.query(
      MYJOURNEY_LIST_QUERY, {
        limit,
        offset,
        search
      },
      'myjourneys'
    )
  }

}