<template>
  <v-app>
    <AppBar />

    <v-main>
      <router-view/>
    </v-main>

    <ApplicationErrorMessage />
  </v-app>
</template>

<script>
import AppBar from '@/components/app/AppBar'
import ApplicationErrorMessage from '@/components/app/ApplicationErrorMessage'

export default {
  name: 'App',
  components: {
    AppBar,
    ApplicationErrorMessage,
  }
}
</script>
