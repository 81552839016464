/* eslint-disable no-unused-vars */
import 'babel-polyfill'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import apolloClient from './apolloClient'
import VueApollo from 'vue-apollo'
import Vuetify from "vuetify";
import vuetifyDe from 'vuetify/src/locale/de.ts'
import vuetifyEn from 'vuetify/src/locale/en.ts'
import i18n from "./i18n";
import A3MAuth from './plugins/A3MAuth'
import RepositoriesPlugin from './plugins/Repositories'
import './filters';
import "vuetify/dist/vuetify.min.css";
//import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'leaflet/dist/leaflet.css';
import './libs/MoveableVuetifyDialog'
import './libs/MoveableVuetifyDialog.css';
import VuetifyConfirm from 'vuetify-confirm';
import VueQuillEditor from 'vue-quill-editor'
import MarqueeText from 'vue-marquee-text-component'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme

let language = 'de'
if (localStorage.getItem("language")) {
  language = localStorage.getItem("language")
}

Vue.config.productionTip = false
Vue.use(Vuetify);
Vue.use(VueApollo)
Vue.use(A3MAuth)
Vue.use(RepositoriesPlugin, store)
Vue.use(VueQuillEditor, {
  placeholder: "",
  formats: ['bold', 'italic', 'underline', 'strike', 'link'],
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
    ],
    clipboard: {
      matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
    }
  }
})
Vue.component('marquee-text', MarqueeText)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading'
  }
})

const vuetify = new Vuetify({
  lang: {
    locales: { vuetifyDe, vuetifyEn },
    current: language,
  },
  icons: {
    iconfont: 'md',
  },
})

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t('main.YES'),
  buttonFalseText: i18n.t('main.NO')
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

/* Init some stores, so that they can load content from the localStorage */
store.dispatch("authStore/initStore")
store.dispatch("uiStore/initStore")

