export function apolloVuexBaseFactory(initStore, defaultStore, params) {
  // we deep copy the default store and make it the base for our new store
  let store = { ... initStore }

  for (let source of [defaultStore, params]) {
    let segments = {
      state: Object.prototype.hasOwnProperty.call(source, "state") ? source['state'] : {},
      actions: Object.prototype.hasOwnProperty.call(source, "actions") ? source['actions'] : {},
      getters: Object.prototype.hasOwnProperty.call(source, "getters") ? source['getters'] : {},
      mutations: Object.prototype.hasOwnProperty.call(source, "mutations") ? source['mutations'] : {}
    }

    for (let segmentKey of Object.keys(segments)) {
      for (let key of Object.keys(segments[segmentKey])) {
        store[segmentKey][key] = segments[segmentKey][key]
      }
    }

    // settings is a little bit special...
    let settings = Object.prototype.hasOwnProperty.call(source, "settings") ? source['settings'] : {}
    for (let key of Object.keys(settings)) {
      store['state']['settings'][key] = settings[key]
    }
  }

  return store
}