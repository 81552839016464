import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { ORGANISATION_QUERY, ORGANISATION_UPDATE_MUTATION } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: ORGANISATION_QUERY,
    fetchFieldName: 'organisation',

    updateMutation:  ORGANISATION_UPDATE_MUTATION,
    updateMutationName: 'updateOrganisation',
    updateFieldName: 'organisation',
  },
  actions: {
    getIdFromUpdateVariables({ state, commit, rootState }, variables) {
      return variables.input.id
    },
  }
})
/* eslint-enable no-unused-vars */

export default store