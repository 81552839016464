<template>
  <v-progress-linear
    :active="loading"
    indeterminate
    height="4"
    class="ma-0"
    style="top: -2px;"
  ></v-progress-linear>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppBarLoadingAnimation',
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
  }
};
</script>