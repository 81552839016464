import gql from 'graphql-tag'

import { COUNTRY_FRAGMENT, REGION_FRAGMENT, AIRPORT_FRAGMENT } from "../../graphql";

// returns the country with all its regions and airports
export const COUNTRY_QUERY = gql`
query Country($id: ID!) {
  country(id: $id) {
    ...CountryFragment
    regions {
      ...RegionFragment
      airports {
        ...AirportFragment
      }
    }
    airports(regionId_Isnull: true) {
      ...AirportFragment
    }
  }
}
${COUNTRY_FRAGMENT}
${REGION_FRAGMENT}
${AIRPORT_FRAGMENT}
`