import gql from 'graphql-tag'

export const TEXTSNIPPETS_QUERY = gql`
query TextSnippets {
  textsnippets {
    results {
      id
      name
      text
      textDe
      textEn
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      modifiedAt
      modifiedBy {
        id
        firstName
        lastName
      }
    }
  }
}
`



export const TEXTSNIPPETS_CREATE_MUTATION = gql`
mutation CreateTextSnippet($input: TextSnippetCreateGenericType!) {
  createTextsnippet(input: $input) {
    textsnippet {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`

export const TEXTSNIPPETS_UPDATE_MUTATION = gql`
mutation UpdateTextSnippet($input: TextSnippetUpdateGenericType!) {
  updateTextsnippet(input: $input) {
    textsnippet {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`


export const TEXTSNIPPETS_DELETE_MUTATION = gql`
mutation DeleteTextSnippet($id: ID!) {
  deleteTextsnippet(id: $id) {
    textsnippet {
      id
    }
    ok
    errors {
      field
      messages
    }
  }
}
`
