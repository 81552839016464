<template>
  <v-snackbar
    v-model="showErrorMessage"
    :timeout="timeout"
  >
    {{ $t("main.ERROR") }}: {{ errorMessage }}
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ApplicationErrorMessage',
  data() {
    return {
      timeout: 2000,
      showErrorMessage: false,
      lastErrorId: null,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    processErrors(errors) {
      if (errors.length == 0) {
        return
      }

      let lastError = errors[errors.length-1]
      if (lastError.id == this.lastErrorId) {
        return
      }

      this.errorMessage = lastError.message
      this.showErrorMessage = true
      this.lastErrorId = lastError.id
    }
  },
  watch: {
    errors() {
      this.processErrors(this.errors)
    }
  }
};
</script>