import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { ENTRYRESTRICTION_WITH_PARENT_QUERY  } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: ENTRYRESTRICTION_WITH_PARENT_QUERY,
    fetchFieldName: 'entryRestrictionWithParent',
  },
})
/* eslint-enable no-unused-vars */

export default store

