import moment from "moment"
import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { TICKER_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: TICKER_QUERY,
    fetchAllFieldName: 'ticker',
  },
  getters: {
    withIsUnread(state, getters, rootState) {
      let news = []
      for (let entry of state.entries) {
        entry.isUnread = false
        if (moment(entry.createdAt) > rootState.uiStore.lastNewsVisitDate) {
          entry.isUnread = true
        }
        news.push(entry)
      }
      return news
    },

  }
})
/* eslint-enable no-unused-vars */

export default store

