import gql from 'graphql-tag'

import { ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT } from '../../graphql'

export const ENTRYRESTRICTION_WITH_PARENT_QUERY = gql`
query EntryRestrictionWithParentQuery($id: ID!) {
  entryRestrictionWithParent(id: $id) {
    entryrestriction {
      ...EntryRestrictionWithRelationsFragment
      reminder
      internalNote
      internalSources
      regionDeviationTourism
      regionDeviationBusiness
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateBy {
        id
        firstName
        lastName
      }
      checkedAt
      checkedBy {
        id
        firstName
        lastName
      }
    }
    entryrestrictionParent {
      ...EntryRestrictionWithRelationsFragment
      reminder
      internalNote
      internalSources
      regionDeviationTourism
      regionDeviationBusiness
      modifiedBy {
        id
        firstName
        lastName
      }
      significantUpdateBy {
        id
        firstName
        lastName
      }
      checkedAt
      checkedBy {
        id
        firstName
        lastName
      }
    }
  }
}
${ENTRYRESTRICTION_WITHRELATIONS_FRAGMENT}
`
