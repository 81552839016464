import { apolloVuexListStoreFactory } from '../../../libs/ApolloVuexListStore'

import { TOUROPERATOREXTERNALRESTRICTIONINFOS_QUERY } from "./graphql";

/* eslint-disable no-unused-vars */
let store = apolloVuexListStoreFactory({
  settings: {
    fetchAllQuery: TOUROPERATOREXTERNALRESTRICTIONINFOS_QUERY,
    fetchAllFieldName: 'touroperatorexternalrestrictioninfos',
  },
})
/* eslint-enable no-unused-vars */

export default store

