import { apolloVuexSingleStoreFactory } from '../../../libs/ApolloVuexSingleStore'
import { ENTRYRESTRICTION_QUERY, ENTRYRESTRICTION_CREATE_MUTATION, ENTRYRESTRICTION_UPDATE_MUTATION } from "./graphql";
import axiosClient from '../../../axiosClient';
import { backendDownloadsUrl } from '../../../settings'

/* eslint-disable no-unused-vars */
let store = apolloVuexSingleStoreFactory({
  settings: {
    fetchQuery: ENTRYRESTRICTION_QUERY,
    fetchFieldName: 'entryRestriction',

    createMutation: ENTRYRESTRICTION_CREATE_MUTATION,
    createMutationName: 'createEntryrestriction',
    createFieldName: 'entryrestriction',

    updateMutation: ENTRYRESTRICTION_UPDATE_MUTATION,
    updateMutationName: 'updateEntryrestriction',
    updateFieldName: 'entryrestriction',
  },
  actions: {
    getIdFromUpdateVariables({ state, commit, rootState }, variables) {
      return variables.input.id
    },
    updateEntryDataOnUpdate({ state, commit, rootState }, content) {
      commit('setEntryData', { id: content.id, data: content.result.data.updateEntryrestriction.entryrestriction } )
      commit('setEntryIsDone', { id: content.id, isDone: true } )
    },

  }
})
/* eslint-enable no-unused-vars */

export default store

