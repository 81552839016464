<template>
  <v-app-bar app v-if="authToken" extension-height="0">
    <v-toolbar-title class="headline">
      <router-link :to="{name: 'index'}" class="site-name"><img src="@/assets/logo_dm.png" class="header-logo" /></router-link>
    </v-toolbar-title>

    <v-btn
      v-if="$hasPermission('a3m_core.DestinationManager')"
      class="ml-12"
      text
      color="primary"
      :to="{name: 'index'}"
    >
      <v-icon class="mr-2">event_note</v-icon>

      <v-badge v-if="unreadNewsCount" color="green" :content="unreadNewsCount">Newsboard</v-badge>
      <span  v-if="!unreadNewsCount" >{{ $t("main.NEWSBOARD") }}</span>
    </v-btn>

    <span v-if="$hasPermission('a3m_core.DestinationManager')">
      <v-divider class="mx-2" vertical></v-divider>
      <v-btn
        text
        color="primary"
        :to="{name: 'destination-map'}"
      >
        <v-icon class="mr-2">map</v-icon>

        <span>{{ $t("main.MAP_PAGE_BUTTON") }}</span>
      </v-btn>
    </span>

    <span  v-if="$hasPermission('a3m_core.DestinationManager')">
      <v-divider class="mx-2" vertical></v-divider>
      <v-btn
        outlined
        color="primary"
        :to="{name: 'destination-empty'}"
      >
        <v-icon class="mr-2">search</v-icon>
        {{ $t("main.DESTINATIONS") }}
      </v-btn>
    </span>

    <span  v-if="$hasPermission('a3m_core.DestinationManagerListMyJourney')">
      <v-divider class="mx-2" vertical></v-divider>
      <v-btn
        text
        color="primary"
        :to="{name: 'mytrip-history'}"
      >
        <v-icon class="mr-2">commute</v-icon>
        {{ $t("main.MYJOURNEY_PAGES") }}
      </v-btn>
    </span>

    <v-spacer />

    <span  v-if="$hasPermission('a3m_core.DestinationManagerTOInfo')">
      <v-btn
        text
        color="primary"
        :to="{name: 'informationcenter'}"
      >
        {{ $t("main.INFOTHEK") }}
      </v-btn>
    </span>

    <span v-if="$hasPermission('a3m_core.DestinationManagerCDB') && cdbUrl">
      <v-divider class="mx-2" vertical></v-divider>
      <v-btn
        text
        color="primary"
        :href="cdbUrl"
        target="_blank"
      >
        {{ $t("main.COUNTRYDATABASE") }}
      </v-btn>
    </span>

    <ToolbarBurgerMenu />
    <span class="ml-2 mr-2">
      <ToolbarAvatar  />
    </span>
    <AppBarLoadingAnimation slot="extension"/>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import AppBarLoadingAnimation from '@/components/app/AppBarLoadingAnimation'
import ToolbarAvatar from '@/components/app/ToolbarAvatar'
import ToolbarBurgerMenu from '@/components/app/ToolbarBurgerMenu'


export default {
  name: 'AppBar',
  components: {
    AppBarLoadingAnimation,
    ToolbarAvatar,
    ToolbarBurgerMenu,
  },
  computed: {
    ...mapState({
      authToken: state => state.authStore.authToken,
      user: state => state.authStore.user,
      loading: state => state.loading,
      queriesInProgress: state => state.queriesInProgress,
    }),
    ...mapGetters({
      unreadNewsCount: 'uiStore/unreadNewsCount',
      cdbUrl: 'authStore/cdbUrl'
    })
  }
}
</script>

<style scoped>
  .header-logo {
    height: 34px;
    margin-right: 0.4rem;
    margin-top: 10px;
  }

  .site-name {
    color: #6f6f6f;
    text-decoration: none;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
</style>

<style>
  .v-toolbar__extension {
    padding: 0px !important;
  }

  .v-data-table th {
    font-size: 0.875rem!important;
  }

  .v-toolbar__content .v-btn__content {
    text-transform: none!important;
  }

  .v-toolbar__content .v-divider--vertical {
    height: 20px!important;
  }
</style>